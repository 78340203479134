import React, {useState} from "react";
import {Helmet} from "react-helmet";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";

import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';

import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import IconButton from "@material-ui/core/IconButton";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';

import { authService } from "services/authService.js";
import apiService from "services/apiService.js";
import config from "constants/config.js";

import LoadingSpinner from "components/Loading/Loading.js";

import styles from "assets/jss/material-dashboard-pro-react/views/newInvestorStyle.js";

const useStyles = makeStyles(styles);

export default function MultiAccount(props) {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);

  const [accountTypeError, setAccountTypeError] = useState(false);

  const [userList, setUserList] = useState([]);
  const [typeArr, setTypeArr] = useState([]);

  const [address, setAddress] = useState("");
  const [type, setType] = useState(0);

  const [email, setEmail] = useState([""]);
  const [numberUser, setNumberUser] = useState(1);

  React.useEffect(() => {
    apiService(config.api_url + "?type=get_customer_type", "POST", {}, function(typeRes) {
      setTypeArr([...typeRes.data]);
      setLoading(false);
    }, function(typeErr) {
      setLoading(false);
      console.log(typeErr);
    })
  }, [])

  return (
    <div>
      <Helmet>
        <title>Multiple Customers | {config.appName}</title>
      </Helmet>
      <GridContainer>
        {loading && <LoadingSpinner />}
        {!loading && (<GridItem xs={12}>
          <Box px={2} py={1}>
            <h3 className={classes.title}>
              Add Multiple Customers
            </h3>
          </Box>
          <ValidatorForm onSubmit={e => {
            e.preventDefault();
            if (!type || type === "0" || type === 0) {
              setAccountTypeError(true);
              return;
            }

            let formData = new FormData();

            formData.append("type", "create_multi_users");
            // formData.append("email", account.email);
            for (let i = 0; i < email.length; i ++) {
              formData.append("email[" + i + "]", email[i]);
            }

            formData.append("type_id", type);
            formData.append("address", address);

            setLoading(true);
            apiService(config.api_url, "POST", formData, function(response) {
              setLoading(false);
              
              alert("You've added multiple users successfully!");
              props.history.push("/dashboard/accounts");
            }, function(err) {
              setLoading(false);
              alert("Occured error during add users. Please refresh page");
              console.log(err);
            })
          }}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={9} lg={6}>
                <Card className={classes.profileCard}>
                  <CardBody>
                    {email.map((item, key) => {
                      return (
                        <Box py={1} key={key}>
                          <TextValidator 
                            name="email"
                            label={`Email of User ${key + 1}`}
                            value={item}
                            variant="outlined"
                            fullWidth={true}
                            className={classes.input}
                            validators={['required', 'isEmail']}
                            errorMessages={['This field is required', 'Invalid email']}
                            onChange={e => {
                              email[key] = e.target.value;
                              setEmail([...email]);
                            }}
                          />
                        </Box>
                      )
                    })}
                    
                    <Box py={1}>
                      <IconButton aria-label="expand row" onClick={() => {
                        setNumberUser(numberUser + 1);
                        email.push("");
                        setEmail([...email]);
                      }}>
                        <AddCircleOutlineIcon />
                      </IconButton>
                    </Box>
                    <Box py={1}>
                      <TextField
                        select
                        value={type}
                        className={classes.selectType}
                        label="Account Type"
                        variant="outlined"
                        fullWidth={true}
                        onChange={e => {
                          setType(e.target.value);
                          setAccountTypeError(false);
                        }}
                        error={accountTypeError}
                        helperText={accountTypeError ? "Please select account type" : ""}
                      >
                        <MenuItem aria-label="None" value={0} >Select Type</MenuItem>
                        {typeArr.map((type, key) => {
                          return <MenuItem aria-label="None" value={parseInt(type.id)} key={key} >{type.type}</MenuItem>
                        })}
                      </TextField>
                    </Box>
                    <Box py={1}>
                      <TextValidator 
                        name="address"
                        label="Address"
                        value={address}
                        variant="outlined"
                        fullWidth={true}
                        className={classes.input}
                        validators={['required']}
                        errorMessages={['This field is required']}
                        onChange={e => {
                          setAddress(e.target.value);
                        }}
                      />
                    </Box>
                    <Box py={1} className={classes.rightButton}>
                      <Button size="lg" type="submit">
                        Add
                      </Button>
                    </Box>
                  </CardBody>
                </Card>
                    
              </GridItem>
            </GridContainer>
          </ValidatorForm>
        </GridItem>)}
      </GridContainer>
    </div>
  );
}
