import React, {useEffect} from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Link from "@material-ui/core/Link";

// @material-ui/icons
import Menu from "@material-ui/icons/Menu";

// core components
import Button from "components/CustomButtons/Button";

import styles from "assets/jss/material-dashboard-pro-react/components/authNavbarStyle.js";

const useStyles = makeStyles(styles);

export default function AuthNavbar(props) {
  const [open, setOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setOpen(!open);
  };
  // verifies if routeName is the one active (in browser input)
  const activeRoute = routeName => {
    return window.location.href.indexOf(routeName) > -1 ? true : false;
  };
  const classes = useStyles();
  const { color, logo, mLogo, className } = props;
  const appBarClasses = cx({
    [" " + classes[color]]: color
  });

  const [scrollTop, setScrollTop] = React.useState(document.body.scrollTop);

  useEffect(() => {
    document.addEventListener("scroll", handleOnScroll);
    return () => {
      document.removeEventListener("scroll", handleOnScroll);
    }
  }, []);

  const handleOnScroll = (e) => {
    setScrollTop(window.scrollY);
  }

  var list = (
    <div className={classes.listContainer}>
    </div>
  );
  return (
    <AppBar position="static" className={classes.appBar + appBarClasses + ((scrollTop !== 0 || className === 'parallax') ? (" " + className) : "")}>
      <Toolbar className={classes.container}>
        <Hidden smDown>
          <div className={classes.logoNav}>
            <Button href="/" className={classes.title} color="transparent">
              <img src={logo} className={classes.logo} alt="logo"/>Teleplay
            </Button>
          </div>
        </Hidden>
        <Hidden mdUp>
          <div className={classes.flex}>
            <Button href="/" className={classes.title} color="transparent">
              <img src={mLogo} className={classes.logo} alt="logo"/>
            </Button>
          </div>
        </Hidden>
        <Hidden smDown>{list}</Hidden>
        <Hidden mdUp>
          <Button
            className={classes.sidebarButton}
            color="transparent"
            justIcon
            aria-label="open drawer"
            onClick={handleDrawerToggle}
          >
            <Menu />
          </Button>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

AuthNavbar.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  brandText: PropTypes.string
};
