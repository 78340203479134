const viewInvestorStyle = {
	flexBox: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between"
	},
	rightButton: {
		textAlign: "right",
		"& button": {
			backgroundColor: "#009F9B",
			textTransform: "inherit",
			color: "#fff",
			fontSize: "14px",
			padding: "8px 20px",
			borderRadius: "3px",
			margin: 0
		},
		"& button:last-child": {
			marginLeft: "15px"
		}
	},
	profileCard: {
		margin: "15px 0"
	},
	profileCardHeader: {
		textAlign: "left"
	},
	rightAlign: {
		textAlign: "right"
	},
	title: {
		fontWeight: "bold",
		margin: "10px 0"
	},
	subTitle: {
		fontSize: "20px",
		fontWeight: "bold"
	}
}

export default viewInvestorStyle;