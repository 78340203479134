import {
  defaultFont,
  container,
  containerFluid,
  primaryColor,
  whiteColor,
  grayColor,
  mainColor
} from "assets/jss/material-dashboard-pro-react.js";

const footerStyle = {
  block: {
    textTransform: "inherit",
    fontFamily: "Fira Sans",
    fontSize: "16px",
    fontWeight: "400",
    color: "#fff"
  },
  left: {
    float: "left!important",
    display: "block"
  },
  right: {
    margin: "0",
    fontSize: "14px",
    float: "right!important",
    padding: "15px"
  },
  footer: {
    bottom: "0",
    borderTop: "1px solid " + grayColor[15],
    padding: "40px 0",
    ...defaultFont,
    zIndex: 4,
    backgroundColor: mainColor,
    display: "flow-root",
    "& h4": {
      color: "#fff",
      fontFamily: "Fira Sans",
      fontSize: "15px"
    }
  },
  container: {
    zIndex: 3,
    ...container,
    position: "relative"
  },
  logo: {
    padding: "0",
    width: "260px",
    "& img": {
      width: "100%"
    }
  },
  footerContainer: {
    padding: "0 15px",
    position: "relative",
    zIndex: 3,
    display: "block"
  },
  containerFluid: {
    zIndex: 3,
    ...containerFluid,
    position: "relative"
  },
  a: {
    color: primaryColor[0],
    textDecoration: "none",
    backgroundColor: "transparent"
  },
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0"
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0",
    width: "auto"
  },
  footerMenu: {
    display: "block",
    padding: "0",
    width: "auto",
    "& a": {
      padding: "8px 0"
    },
    "&:first-child a": {
      fontWeight: 600,
      padding: "16px 0"
    }
  },
  whiteColor: {
    "&,&:hover,&:focus": {
      color: whiteColor
    }
  },
  terms: {
    marginTop: "40px"
  },
  footerLogo: {
    display: "flex",
    alignItems: "flex-end",
    "@media (max-width: 768px)": {
      alignItems: "center"
    }
  },
  copyRight: {
    color: "#fff",
    fontSize: "16px",
    fontWeight: 400
  }
};
export default footerStyle;
