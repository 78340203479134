import React, {useState} from "react";
import {Helmet} from "react-helmet";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import IconButton from '@material-ui/core/IconButton';

import Box from "@material-ui/core/Box";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

import { authService } from "services/authService.js";
import apiService from "services/apiService.js";
import config from "constants/config.js";

import {utils} from "utils/index.js";
import LoadingSpinner from "components/Loading/Loading.js";

import styles from "assets/jss/material-dashboard-pro-react/views/investorsStyle.js";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import MUIDataTable from "mui-datatables";

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

const useStyles = makeStyles(styles);
const emptyIcon = require("assets/img/emptyData.svg");

export default function Admins(props) {
  const classes = useStyles();

  const [loading, setLoading] = useState(true);

  const [updateArr, setUpdateArr] = useState([]);

  const getMuiTheme = () => createMuiTheme({
    overrides: {
      MUIDataTableBodyRow: {
        root: {
          cursor: 'pointer'
        }
      }
    }
  });

  React.useEffect(() => {
    setLoading(true);
    apiService(config.endpoint.getAdmins, "GET", {}, function(response) {
      setLoading(false);
      setUpdateArr(response.users);
    }, function(err) {
      setLoading(false);
      console.log(err);
    }, authService.getToken());

    return () => {
      
    }
  }, [])

  const columns = [
    {
      name: 'name',
      label: "Name",
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return updateArr[dataIndex].firstName + " " + updateArr[dataIndex].lastName;
        },
        sort: true
      }
    },
    {
      name: 'email',
      label: "Email"
    },
    {
      name: 'emailConfirmed',
      label: "Email Confirmed",
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return updateArr[dataIndex].emailConfirmed ? "Yes" : "No"
        }
      }
    },
    {
      name: 'active',
      label: 'Active',
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return updateArr[dataIndex].status ? "Yes" : "No"
        }
      }
    }
  ];

  const options = {
    selectableRows: 'none',
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    onRowClick: function(rowData, rowMeta) {
      props.history.push('/dashboard/admin/view/' + updateArr[rowMeta.dataIndex]._id);
    },
    customToolbar: function(data) {
      return (<React.Fragment>
        <IconButton aria-label="expand row" onClick={() => {
          props.history.push("/dashboard/admin/new");
        }}>
          <AddCircleOutlineIcon />
        </IconButton>
      </React.Fragment>)
    }
  }

  return (
    <div>
      <Helmet>
        <title>Admins | AltExchange</title>
      </Helmet>
      <GridContainer>
        {loading && <LoadingSpinner />}
        {!loading && (<GridItem xs={12}>
          <Box px={2} py={2}>
            <h3>
              All Admin
            </h3>
          </Box>
          <MuiThemeProvider theme={getMuiTheme()}>
            <MUIDataTable
              data={updateArr}
              columns={columns}
              options={options} 
              className={classes.dataTable} />
          </MuiThemeProvider>
        </GridItem>)}
      </GridContainer>
    </div>
  );
}
