import {
  drawerWidth,
  drawerMiniWidth,
  transition,
  containerFluid
} from "assets/jss/material-dashboard-pro-react.js";

const appStyle = theme => ({
  wrapper: {
    position: "relative",
    top: "0",
    height: "100vh",
    "&:after": {
      display: "table",
      clear: "both",
      content: '" "'
    }
  },
  mainPanel: {
    transitionProperty: "top, bottom, width",
    transitionDuration: ".2s, .2s, .35s",
    transitionTimingFunction: "linear, linear, ease",
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`
    },
    overflow: "auto",
    position: "relative",
    float: "right",
    ...transition,
    maxHeight: "100%",
    width: "100%",
    overflowScrolling: "touch"
  },
  content: {
    padding: "0px 15px 0px",
    minHeight: "calc(100vh - 71px)",
    backgroundColor: "#fbfbfb",
    "@media (max-width: 768px)": {
      padding: "30px 0 0"
    },
    position: "relative"
  },
  container: { ...containerFluid },
  map: {
    marginTop: "70px"
  },
  mainPanelSidebarMini: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerMiniWidth}px)`
    }
  },
  mainPanelWithPerfectScrollbar: {
    overflow: "hidden !important"
  },
  pendingBar: {
    backgroundColor: "#009F9B",
    padding: "15px 30px",
    display: "flex",
    alignItems: "center",
    "& p": {
      fontFamily: "Fira Sans",
      fontSize: "16px",
      fontWeight: "500",
      lineHeight: "19px",
      color: "#fff",
      marginBottom: 0,
      marginRight: "40px"
    },
    "& button": {
      textTransform: "inherit",
      backgroundColor: "#fff",
      color: "#009F9B",
      padding: "6px",
      width: "200px",
      fontFamily: "Fira Sans",
      fontWeight: "500",
      fontSize: "16px",
      lineHeight: "19px",
    },
    "@media (max-width: 480px)": {
      display: "block",
      textAlign: "center",
      "& p": {
        marginRight: 0,
        marginBottom: "18px"
      }
    }
  },
});

export default appStyle;
