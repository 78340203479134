import React, {useState} from "react";
import {Helmet} from "react-helmet";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import IconButton from '@material-ui/core/IconButton';
import CreateIcon from '@material-ui/icons/Create';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import { FormControlLabel, Checkbox, Typography } from "@material-ui/core";

import Autocomplete from '@material-ui/lab/Autocomplete';

import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import Collapse from '@material-ui/core/Collapse';

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';

import { authService } from "services/authService.js";
import apiService from "services/apiService.js";
import config from "constants/config.js";

import {utils} from "utils/index.js";
import LoadingSpinner from "components/Loading/Loading.js";

import styles from "assets/jss/material-dashboard-pro-react/views/viewInvestmentStyle.js";

import MUIDataTable from "mui-datatables";

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import ColorPicker from 'material-ui-color-picker'

const useStyles = makeStyles(styles);

export default function ViewExperienceAssets(props) {
	const classes = useStyles();

  const [loading, setLoading] = useState(true);

  const [editProfile, setEditProfile] = useState(false);
  const [categoryList, setCategoryList] = useState({});
  const [file, setFile] = useState({});
  const [categoryError, setCategoryError] = useState(false);

  const [imgStream, setImgStream] = useState("");

  const assetId = props.match.params.id;

  const [asset, setAsset] = useState({});

  const [uploading, setUploading] = useState(false);

  const [loadingBackground, setLoadingBackground] = useState(true);
  const [backgroundList, setBackgroundList] = useState([]);

  const [loadingForeground, setLoadingForeground] = useState(true);
  const [foregroundList, setForegroundList] = useState([]);

  const [loadingMusic, setLoadingMusic] = useState(true);
  const [musicList, setMusicList] = useState([]);
  
  React.useEffect(() => {
    apiService(config.api_url + "?type=get_experience_category", "POST", {}, function(categoryRes) {
      setCategoryList([...categoryRes.data]);

      apiService(config.api_url + "?type=view_experience_asset&id=" + assetId, "POST", {}, function(response) {
        setAsset({...response.data});
        setLoading(false);
      }, function(err) {
        setLoading(false);
        console.log(err);
      })
    }, function(categoryErr) {
      setLoading(false);
      console.log(categoryErr);
    })
  }, []);

  React.useEffect(() => {
    apiService(config.api_url + "?type=get_assets", "POST", {}, function(res) {
      setBackgroundList([...res.data]);
      setLoadingBackground(false);
    }, function(err) {
      setLoadingBackground(false);
      console.log(err);
    });
  }, []);

  React.useEffect(() => {
    apiService(config.api_url + "?type=get_foreground_assets", "POST", {}, function(res) {
      setForegroundList([...res.data]);
      setLoadingForeground(false);
    }, function(err) {
      setLoadingForeground(false);
      console.log(err);
    });
  }, []);

  React.useEffect(() => {
    apiService(config.api_url + "?type=get_music_assets", "POST", {}, function(categoryRes) {
      setMusicList([...categoryRes.data]);
      setLoadingMusic(false);
    }, function(categoryErr) {
      setLoadingMusic(false);
      console.log(categoryErr);
    });
  }, []);

  return (
    <div>
      <Helmet>
        <title>View Foreground Asset | {config.appName}</title>
      </Helmet>
      <GridContainer>
        {(loading || uploading || loadingBackground || loadingForeground || loadingMusic) && <LoadingSpinner opacity={uploading ? 1 : 0.2} />}
        {(!loading && !loadingBackground && !loadingForeground && !loadingMusic) && (<GridItem xs={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={9} lg={6}>
              <Box px={2} py={1} className={classes.titleBox}>
                <h3 className={classes.title}>
                  View Foreground Asset
                </h3>
              </Box>
              <Card>
                <CardBody>
              		{!editProfile && <Box className={classes.rightAlign}>
              			<IconButton onClick={e => {
                      setEditProfile(!editProfile);
                    }} title="Edit Asset">
                      <CreateIcon />
                    </IconButton>
                    <IconButton onClick={e => {
                      let confirm = window.confirm("Are you sure to remove this asset?");
                      if (confirm) {
                        setLoading(true);
                        apiService(config.api_url + "?type=delete_experienceAsset&id=" + assetId, "POST", {}, function(response) {
                          setLoading(false);
                          alert("You removed asset successfully!");
                          props.history.push("/dashboard/experienceassets");
                        }, function(err) {
                          setLoading(false);
                          alert("Occured error during remove asset.");
                        })
                      }
                    }} title="Remove Asset">
                      <DeleteIcon />
                    </IconButton>
              		</Box>}
              		<ValidatorForm onSubmit={e => {
              			e.preventDefault();

                    if (!asset.category_id || asset.category_id === "0") {
                      setCategoryError(true);
                      return;
                    }

                    let formData = new FormData();
                    formData.append("type", "update_experienceasset");
                    formData.append("category_id", asset.category_id);
                    formData.append("id", asset.id);
                    formData.append("title", asset.title);
                    if (file && file !== {})
                      formData.append("fileToUpload", file);

                    formData.append("background_id", asset.background_id);
                    formData.append("foreground_id", asset.foreground_id);
                    formData.append("music_id", asset.music_id);

                    formData.append("width", asset.width);
                    formData.append("height", asset.height);
                    formData.append("x", asset.x);
                    formData.append("y", asset.y);
                    formData.append("color", asset.color ? utils.hexToRgba(asset.color) : "rgba(0,0,0,1)");
                    formData.append("grayscale", (asset.grayscale !== "0" && asset.grayscale) ? 1 : 0);
                    formData.append("musicloop", (asset.musicloop !== "0" && asset.musicloop) ? 1 : 0);

                    setUploading(true);
                    apiService(config.api_url, "POST", formData, function(response) {
                      setUploading(false);
                      if (response.data.thumb_url) {
                        asset.thumb_url = response.data.thumb_url;
                      }
                      setAsset({...asset});
                      setFile({});
                      setEditProfile(false);
                    }, function(err) {
                      setUploading(false);
                      console.log(err);
                    })
              		}}>
              			<Box py={1}>
              				<TextValidator 
                        name="title"
                        label="Title"
                        value={asset.title ? asset.title : ""}
                        variant="outlined"
                        fullWidth={true}
                        className={classes.input}
                        validators={['required']}
                        errorMessages={['This field is required']}
                        onChange={e => {
                          asset.title = e.target.value;
                          setAsset({...asset});
                        }}
                        disabled={!editProfile}
                      />
              			</Box>
                    <Box py={1}>
                      <TextField
                        select
                        value={asset.category_id ? parseInt(asset.category_id) : 0}
                        className={classes.selectType}
                        label="Category"
                        variant="outlined"
                        fullWidth={true}
                        onChange={e => {
                          asset.category_id = e.target.value;
                          setAsset({...asset});
                          setCategoryError(false);
                        }}
                        error={categoryError}
                        helperText={categoryError ? "Please select category" : ""}
                        disabled={!editProfile}
                      >
                        <MenuItem aria-label="None" value={0} >Select Type</MenuItem>
                        {categoryList.map((category, key) => {
                          return <MenuItem aria-label="None" value={parseInt(category.id)} key={key} >{category.category}</MenuItem>
                        })}
                      </TextField>
                    </Box>
                    <Box py={1}>
                      <img src={imgStream ? imgStream : asset.thumb_url} style={{
                        width: "50px"
                      }} />
                    </Box>
                    <Box py={1} className={classes.flexBox}>
                      <Link
                        href="#"
                        component="label"
                        onClick={e => {
                          if (!editProfile) {
                            e.preventDefault();
                            e.stopPropagation();
                            return false;
                          }
                        }}>
                        Change Thumbnail<input
                          type="file"
                          id="attachFile"
                          hidden
                          onChange={e => {
                            const fileReader = new FileReader();

                            setFile(e.target.files[0]);
                            setImgStream(URL.createObjectURL(e.target.files[0]));
                          }}
                          name="attachments"
                          accept="image/png, image/jpeg"
                        />
                      </Link>
                      {(file !== {}) && <p>
                        {file.name}
                      </p>}
                    </Box>
                    <Box py={1}>
                      <Autocomplete
                        value={asset.background_id}
                        options={backgroundList}
                        getOptionLabel={(option) => {
                          if (!option.title) {
                            let selectedValue = backgroundList.filter((background) => background.id === option);
                            return selectedValue[0].title;
                          } else {
                            return option.title;
                          }
                        }}
                        renderInput={(params) => <TextField {...params} label="Background Asset" variant="outlined" />}
                        onChange={(event, newValue) => {
                          asset.background_id = newValue?.id;
                          setAsset({...asset});
                        }}
                        getOptionSelected={(option, value) => {
                          return option.id === value;
                        }}
                        disabled={!editProfile}
                      />
                    </Box>
                    <Box py={1}>
                      <Autocomplete
                        value={asset.foreground_id}
                        options={foregroundList}
                        getOptionLabel={(option) => {
                          if (!option.title) {
                            let selectedValue = foregroundList.filter((background) => background.id === option);
                            return selectedValue[0].title;
                          } else {
                            return option.title;
                          }
                        }}
                        renderInput={(params) => <TextField {...params} label="Foreground Asset" variant="outlined" />}
                        onChange={(event, newValue) => {
                          asset.foreground_id = newValue?.id;
                          setAsset({...asset});
                        }}
                        getOptionSelected={(option, value) => {
                          return option.id === value
                        }}
                        disabled={!editProfile}
                      />
                    </Box>
                    <Box py={1}>
                      <Autocomplete
                        value={asset.music_id}
                        options={musicList}
                        getOptionLabel={(option) => {
                          if (!option.title) {
                            let selectedValue = musicList.filter((background) => background.id === option);
                            return selectedValue[0].title;
                          } else {
                            return option.title;
                          }
                        }}
                        renderInput={(params) => <TextField {...params} label="Music Asset" variant="outlined" />}
                        onChange={(event, newValue) => {
                          asset.music_id = newValue?.id;
                          setAsset({...asset});
                        }}
                        getOptionSelected={(option, value) => {
                          return option.id === value;
                        }}
                        disabled={!editProfile}
                      />
                    </Box>
                    <Box py={1}>
                      <Typography
                        style={{
                          fontSize: "18px",
                          fontWeight: "bold"
                        }}
                      >
                        Size of Foreground
                      </Typography>
                    </Box>
                    <Box py={1}>
                      <TextValidator 
                        name="width"
                        label="Width Multiplier(0~1)"
                        value={asset.width ? asset.width : ""}
                        variant="outlined"
                        fullWidth={true}
                        className={classes.input}
                        validators={['required', 'isFloat', 'minFloat:0', 'maxFloat:1']}
                        errorMessages={['This field is required.', 'This field is number.', 'This field should be greater than 0.', 'This field should be smaller than 1']}
                        onChange={e => {
                          asset.width = e.target.value;
                          setAsset({...asset});
                        }}
                        disabled={!editProfile}
                      />
                    </Box>
                    <Box py={1}>
                      <TextValidator 
                        name="height"
                        label="Height Multiplier(0~1)"
                        value={asset.height ? asset.height : ""}
                        variant="outlined"
                        fullWidth={true}
                        className={classes.input}
                        validators={['required', 'isFloat', 'minFloat:0', 'maxFloat:1']}
                        errorMessages={['This field is required.', 'This field is number.', 'This field should be greater than 0.', 'This field should be smaller than 1']}
                        onChange={e => {
                          asset.height = e.target.value;
                          setAsset({...asset});
                        }}
                        disabled={!editProfile}
                      />
                    </Box>
                    <Box py={1}>
                      <Typography
                        style={{
                          fontSize: "18px",
                          fontWeight: "bold"
                        }}
                      >
                        Location of Foreground
                      </Typography>
                    </Box>
                    <Box py={1}>
                      <TextValidator 
                        name="x"
                        label="X Multiplier(0~2)"
                        value={asset.x ? asset.x : ""}
                        variant="outlined"
                        fullWidth={true}
                        className={classes.input}
                        validators={['required', 'isFloat', 'minFloat:0', 'maxFloat:2']}
                        errorMessages={['This field is required.', 'This field is number.', 'This field should be greater than 0.', 'This field should be smaller than 2']}
                        onChange={e => {
                          asset.x = e.target.value;
                          setAsset({...asset});
                        }}
                        disabled={!editProfile}
                      />
                    </Box>
                    <Box py={1}>
                      <TextValidator 
                        name="y"
                        label="Y Multiplier(0~2)"
                        value={asset.y ? asset.y : ""}
                        variant="outlined"
                        fullWidth={true}
                        className={classes.input}
                        validators={['required', 'isFloat', 'minFloat:0', 'maxFloat:2']}
                        errorMessages={['This field is required.', 'This field is number.', 'This field should be greater than 0.', 'This field should be smaller than 2']}
                        onChange={e => {
                          asset.y = e.target.value;
                          setAsset({...asset});
                        }}
                        disabled={!editProfile}
                      />
                    </Box>
                    <Box py={1}>
                      <Typography
                        style={{
                          fontSize: "18px",
                          fontWeight: "bold"
                        }}
                      >
                        Filter Color
                      </Typography>
                    </Box>
                    <Box py={1}>
                      <ColorPicker
                        name="color"
                        label="Filter Color"
                        defaultValue={"Change Color"}
                        value={asset.color ? asset.color : "Change Color"}
                        onChange={color => {
                          if (!color || (!editProfile))
                            return;
                          asset.color = color;
                          setAsset({...asset});
                        }}
                        variant="outlined"
                        fullWidth
                        disabled={!editProfile}
                      />
                    </Box>
                    <Box py={1}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={(e) => {
                              // console.log(e.target.checked);
                              asset.grayscale = e.target.checked;
                              setAsset({...asset});
                            }}
                            checked={(asset.grayscale === "0" || !asset.grayscale) ? false : true}
                          />
                        }
                        label={
                          <span>
                            Grayscale
                          </span>
                        }
                        disabled={!editProfile}
                      />
                    </Box>
                    <Box py={1}>
                      <Typography
                        style={{
                          fontSize: "18px",
                          fontWeight: "bold"
                        }}
                      >
                        Music Loop
                      </Typography>
                    </Box>
                    <Box py={1}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={(e) => {
                              // console.log(e.target.checked);
                              asset.musicloop = e.target.checked;
                              setAsset({...asset});
                            }}
                            checked={(asset.musicloop === "0" || !asset.musicloop) ? false : true}
                          />
                        }
                        label={
                          <span>
                            Music
                          </span>
                        }
                        disabled={!editProfile}
                      />
                    </Box>
                    {editProfile && <Box py={1} className={classes.rightButton}>
                    	<Button size="lg" onClick={e => {
                    		setEditProfile(false);
                    	}}>
                    		Cancel
                    	</Button>
                      <Button type="submit" size="lg">
                        Update
                      </Button>
                    </Box>}
              		</ValidatorForm>
              	</CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </GridItem>)}
      </GridContainer>
    </div>
  )
}