const overlayStyle = {
  overlay: {
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: "100",
    backgroundColor: "rgba(42, 66, 102, 0.85)",
    top: 0,
    left: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& div": {
      maxWidth: "380px",
      textAlign: "center",
      "& p": {
        fontFamily: "Open Sans",
        fontWeight: "normal",
        fontSize: "24px",
        lineHeight: "33px",
        textAlign: "center",
        color: "#fff"
      },
      "& button": {
        textTransform: "inherit",
        backgroundColor: "#009F9B",
        padding: "10px",
        width: "265px",
        fontFamily: "Fira Sans",
        fontWeight: "500",
        fontSize: "18px",
        lineHeight: "22px",
        color: "#fff"
      }
    }
  }
}

export default overlayStyle;