import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import Link from "@material-ui/core/Link";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
import Box from "@material-ui/core/Box";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import TextField from "@material-ui/core/TextField";
import CircularProgress  from "@material-ui/core/CircularProgress";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';

import config from "constants/config.js";
import apiService from "services/apiService.js";

const useStyles = makeStyles(styles);

export default function EmailVerificationPage(props) {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  React.useEffect(() => {
    let id = setTimeout(function() {
      setCardAnimation("");
    }, 700);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });
  const classes = useStyles();

  const [email, setEmail] = React.useState("");
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const token = props.match.params?props.match.params.token:"";

    apiService(config.endpoint.emailVerification + token, 'GET', undefined, function(response) {
      setLoading(false);
    }, function(err) {
      console.log(err);
    })
  }, [])

  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={6} style={{textAlign: "center"}}>
        {
          (!loading) && (
          <Card login className={classes[cardAnimaton] + " " + classes.loginCard}>
            <CardHeader className={classes.cardHeader}>
              <h4 className={classes.cardTitle}>Thank you! Your email address has been verified.</h4>
            </CardHeader>
            <CardFooter className={classes.cardFooter}>
              <GridContainer justify="center">
                <GridItem xs={12}>
                  <Button size="lg" className={classes.loginButton} onClick={e => {
                    props.history.push('/admin/login');
                  }}>
                    Login
                  </Button>
                </GridItem>
              </GridContainer>
            </CardFooter>
          </Card>)
        }
        {
          (loading) && (
            <CircularProgress />
          )
        }
        </GridItem>
      </GridContainer>
    </div>
  );
}
