import React, {useState} from "react";
import {Helmet} from "react-helmet";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import IconButton from '@material-ui/core/IconButton';
import CreateIcon from '@material-ui/icons/Create';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteIcon from '@material-ui/icons/Delete';

import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import Collapse from '@material-ui/core/Collapse';

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';

import { authService } from "services/authService.js";
import apiService from "services/apiService.js";
import config from "constants/config.js";

import {utils} from "utils/index.js";
import LoadingSpinner from "components/Loading/Loading.js";

import styles from "assets/jss/material-dashboard-pro-react/views/viewInvestmentStyle.js";

import MUIDataTable from "mui-datatables";

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

const useStyles = makeStyles(styles);

export default function NewExperienceCategory(props) {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);

  const [editProfile, setEditProfile] = useState(false);
  const [category, setCategory] = useState({});
  const [categoryId, setCategoryId] = useState("");
  const [file, setFile] = useState({});
  const [imgStream, setImgStream] = useState("");
  const [categoryError, setCategoryError] = useState(false);
  const [categoryErrorText, setCategoryErrorText] = useState("");

  const [openModal, setOpenModal] = useState(false);

  return (
    <div>
      <Helmet>
        <title>New Experience Category | {config.appName}</title>
      </Helmet>
      <GridContainer>
        {loading && <LoadingSpinner />}
        {!loading && (<GridItem xs={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={9} lg={6}>
              <Box px={2} py={1} className={classes.titleBox}>
                <h3 className={classes.title}>
                  New Experience Category
                </h3>
              </Box>
              <Card>
                <CardBody>
                  <ValidatorForm onSubmit={e => {
                    e.preventDefault();

                    if (imgStream === "") {
                      setCategoryError(true);
                      setCategoryErrorText("You must add asset for category");
                      return;
                    }

                    let formData = new FormData();
                    formData.append("type", "create_experience_category");
                    formData.append("category_name", category.category);
                    if (imgStream !== "") {
                      formData.append("fileToUpload", file);
                    };

                    setLoading(true);
                    apiService(config.api_url, "POST", formData, function(response) {
                      setLoading(false);
                      if (response.status === "Success") {
                        setCategoryId(response.categoryId);
                        setOpenModal(true);
                      } else {
                        alert("Occured error during create new category. Please refresh page and try again.");
                        return;
                      }
                    }, function(err) {
                      setLoading(false);
                      alert("Occured error during create new category. Please refresh page and try again.");
                      console.log(err);
                    })
                  }}>
                    <Box py={1}>
                      <TextValidator 
                        name="name"
                        label="Name"
                        value={category.category ? category.category : ""}
                        variant="outlined"
                        fullWidth={true}
                        className={classes.input}
                        validators={['required']}
                        errorMessages={['This field is required']}
                        onChange={e => {
                          category.category = e.target.value;
                          setCategory({...category});
                          setCategoryError(false);
                        }}
                        error={categoryError}
                        helperText={categoryError ? categoryErrorText : ""}
                      />
                    </Box>
                    <Box py={1}>
                      {(imgStream !== "") && <img src={imgStream} style={{
                        width: "50px"
                      }} />}
                    </Box>
                    <Box py={1}>
                      <Link
                        href="#"
                        component="label"
                      >
                        Add Assets<input
                          type="file"
                          id="attachFile"
                          hidden
                          onChange={e => {
                            const fileReader = new FileReader();

                            setFile(e.target.files[0]);
                            setImgStream(URL.createObjectURL(e.target.files[0]));
                          }}
                          name="attachments"
                          accept="image/png, image/jpeg"
                        />
                      </Link>
                    </Box>
                    <Box py={1} className={classes.rightButton}>
                      <Button type="submit" size="lg">
                        Create
                      </Button>
                    </Box>
                  </ValidatorForm>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
          <Modal
            className={classes.modal}
            open={openModal}
            closeAfterTransition
            disableBackdropClick={true}
            disableEscapeKeyDown={true}>
            <Fade in={openModal}>
              <div className={classes.paper}>
                <h3 id="transition-modal-title">Added New Category.</h3>
                <Box className={classes.twoButton}>
                  <Button size="lg" onClick={e => {
                    props.history.push("/dashboard/experiencecategory/view/" + categoryId);
                  }}>
                    Go to view
                  </Button>
                  <Button size="lg" onClick={e => {
                    setOpenModal(false);
                    setCategory({});
                    setCategoryId("");
                  }}>
                    Continue to add
                  </Button>
                </Box>
              </div>
            </Fade>
          </Modal>
        </GridItem>)}
      </GridContainer>
    </div>
  )
}