const loadingStyle = {
	loading: {
		backgroundColor: "white",
		zIndex: 999,
		textAlign: "center",
		display: "flex",
		minHeight: "100vh",
		alignItems: "center",
		justifyContent: "center",
		position: "absolute",
		width: "100%",
		top: 0,
		"&.transparent": {
			backgroundColor: "rgba(255, 255, 255, 0.2)"
		}
	}
}

export default loadingStyle;