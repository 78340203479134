export const updateAccountInfo = account => ({
	type: "Update Account Info",
  	account,
});

const startState = {
	account: {
		name: "",
		_id: "",
		activated: true
	}
}

export default (state = startState, action) => {
	switch (action.type) {
		case "Update Account Info": {
			return { account: action.account };
		}
		default: 
			return state;
	}
}