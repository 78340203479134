import React, {useState} from "react";
import {Helmet} from "react-helmet";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import IconButton from '@material-ui/core/IconButton';
import CreateIcon from '@material-ui/icons/Create';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import DeleteIcon from '@material-ui/icons/Delete';

import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import Collapse from '@material-ui/core/Collapse';

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';

import { authService } from "services/authService.js";
import apiService from "services/apiService.js";
import config from "constants/config.js";

import {utils} from "utils/index.js";
import LoadingSpinner from "components/Loading/Loading.js";

import styles from "assets/jss/material-dashboard-pro-react/views/viewInvestmentStyle.js";

import MUIDataTable from "mui-datatables";

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

const useStyles = makeStyles(styles);

export default function ViewExperienceCategory(props) {
  const classes = useStyles();

  const [loading, setLoading] = useState(true);

  const [editProfile, setEditProfile] = useState(false);
  const [category, setCategory] = useState({});
  const [file, setFile] = useState({});
  const [imgStream, setImgStream] = useState("");

  const categoryId = props.match.params.id;
  
  React.useEffect(() => {
    // setLoading(false);
    apiService(config.api_url + "?type=view_experience_category&id=" + categoryId, "POST", {}, function(response) {
      setLoading(false);
      setCategory(response.data);
    }, function(err) {
      setLoading(false);
    });
  }, []);

  return (
    <div>
      <Helmet>
        <title>View Experience Category | {config.appName}</title>
      </Helmet>
      <GridContainer>
        {loading && <LoadingSpinner />}
        {!loading && (<GridItem xs={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={9} lg={6}>
              <Box px={2} py={1} className={classes.titleBox}>
                <h3 className={classes.title}>
                  View Category
                </h3>
              </Box>
              <Card>
                <CardBody>
                  {!editProfile && <Box className={classes.rightAlign}>
                    <IconButton onClick={e => {
                      setEditProfile(!editProfile);
                    }} title="Edit Category">
                      <CreateIcon />
                    </IconButton>
                    <IconButton onClick={e => {
                      let confirm = window.confirm("Are you sure to remove this category?");

                      if (confirm) {
                        setLoading(true);
                        apiService(config.api_url + "?type=delete_experience_category&id=" + categoryId, "POST", {}, function(response) {
                          setLoading(false);
                          alert("You removed category successfully!");
                          props.history.push("/dashboard/experiencecategories");
                        }, function(err) {
                          setLoading(false);
                          alert("Occured error during remove category.");
                        })
                      }
                    }} title="Remove Category">
                      <DeleteIcon />
                    </IconButton>
                  </Box>}
                  <ValidatorForm onSubmit={e => {
                    e.preventDefault();

                    let formData = new FormData();
                    formData.append("type", "update_experience_category");
                    formData.append("category", category.category);
                    formData.append("id", category.id);
                    if (imgStream !== "") {
                      formData.append("fileToUpload", file);
                    };

                    setLoading(true);
                    apiService(config.api_url, "POST", formData, function(response) {
                      setLoading(false);
                      if (response.data.thumb_url) {
                        category.thumb_url = response.data.thumb_url;
                      }
                      category.category = response.data.category;
                      setCategory({...category});
                      setImgStream("");
                      setEditProfile(false);
                    }, function(err) {
                      setLoading(false);
                      console.log(err);
                    })
                  }}>
                    <Box py={1}>
                      <TextValidator 
                        name="name"
                        label="Name"
                        value={category.category ? category.category : ""}
                        variant="outlined"
                        fullWidth={true}
                        className={classes.input}
                        validators={['required']}
                        errorMessages={['This field is required']}
                        onChange={e => {
                          category.category = e.target.value;
                          setCategory({...category});
                        }}
                        disabled={!editProfile}
                      />
                    </Box>
                    <Box py={1}>
                      <img src={imgStream ? imgStream : category.thumb_url} style={{
                        width: "50px"
                      }} />
                    </Box>
                    <Box py={1}>
                      <Link
                        href="#"
                        component="label"
                        onClick={e => {
                          if (!editProfile) {
                            e.preventDefault();
                            e.stopPropagation();
                            return false;
                          }
                        }}>
                        Change Assets<input
                          type="file"
                          id="attachFile"
                          hidden
                          onChange={e => {
                            const fileReader = new FileReader();

                            setFile(e.target.files[0]);
                            setImgStream(URL.createObjectURL(e.target.files[0]));
                          }}
                          name="attachments"
                          accept="image/png, image/jpeg"
                        />
                      </Link>
                    </Box>
                    {editProfile && <Box py={1} className={classes.rightButton}>
                      <Button size="lg" onClick={e => {
                        setEditProfile(false);
                      }}>
                        Cancel
                      </Button>
                      <Button type="submit" size="lg">
                        Update
                      </Button>
                    </Box>}
                  </ValidatorForm>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </GridItem>)}
      </GridContainer>
    </div>
  )
}