const newInvestorStyle = {
	rightButton: {
		textAlign: "right",
		"& button": {
			backgroundColor: "#009F9B",
			textTransform: "inherit",
			color: "#fff",
			fontSize: "14px",
			padding: "8px 20px",
			borderRadius: "3px",
			margin: 0
		}
	},
	modal: {
	    display: 'flex',
	    alignItems: 'center',
	    justifyContent: 'center',
  	},
  	paper: {
  		backgroundColor: "#fff",
  		border: '1px solid #000',
  		padding: "20px 30px",
  		boxShadow: "0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%)"
  	},
  	twoButton: {
		textAlign: "right",
		"& button": {
			backgroundColor: "#009F9B",
			textTransform: "inherit",
			color: "#fff",
			fontSize: "14px",
			padding: "8px 20px",
			borderRadius: "3px",
			margin: 0,
			marginRight: "10px"
		}
	},
}

export default newInvestorStyle;