import {
  whiteColor,
  hexToRgb,
  mainColor
} from "assets/jss/material-dashboard-pro-react.js";

const pagesStyle = theme => ({
  wrapper: {
    height: "calc(100vh - 96px)",
    position: "relative",
    top: "0",
  },
  fullPage: {
    position: "relative",
    minHeight: "calc(100vh - 96px)",
    display: "flex!important",
    margin: "0",
    border: "0",
    color: whiteColor,
    alignItems: "center",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    height: "100%",
    "& footer": {
      position: "absolute",
      bottom: "0",
      width: "100%",
      border: "none !important",
      backgroundColor: mainColor,
      color: "#fff"
    },
    "&:before,&:after": {
      display: "block",
      content: '""',
      position: "absolute",
      width: "100%",
      height: "100%",
      top: "0",
      left: "0",
      zIndex: "2"
    }
  },
  mainBar: {
    padding: "6px 0",
    transition: "background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s",
  }
});

export default pagesStyle;
