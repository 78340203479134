import React, {useState} from "react";
import {Helmet} from "react-helmet";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import IconButton from '@material-ui/core/IconButton';
import CreateIcon from '@material-ui/icons/Create';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteIcon from '@material-ui/icons/Delete';

import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import Collapse from '@material-ui/core/Collapse';

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';

import { authService } from "services/authService.js";
import apiService from "services/apiService.js";
import config from "constants/config.js";

import {utils} from "utils/index.js";
import LoadingSpinner from "components/Loading/Loading.js";

import styles from "assets/jss/material-dashboard-pro-react/views/viewInvestmentStyle.js";

import MUIDataTable from "mui-datatables";

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

const useStyles = makeStyles(styles);

export default function NewForegroundAsset(props) {
  const classes = useStyles();

  const [loading, setLoading] = useState(true);

  const [categoryList, setCategoryList] = useState({});
  const [file, setFile] = useState({});
  const [thumb, setThumb] = useState({});
  const [categoryError, setCategoryError] = useState(false);

  const [asset, setAsset] = useState({});

  const [uploading, setUploading] = useState(false);
  const [fileError, setFileError] = useState(false);
  const [fileErrorText, setFileErrorText] = useState("");

  const [openModal, setOpenModal] = useState(false);
  const [assetId, setAssetId] = useState("");

  const [tag, setTag] = useState("");
  
  React.useEffect(() => {
    apiService(config.api_url + "?type=get_foreground_category", "POST", {}, function(categoryRes) {
      setCategoryList([...categoryRes.data]);
      setLoading(false);
    }, function(categoryErr) {
      setLoading(false);
      console.log(categoryErr);
    })
  }, []);

  return (
    <div>
      <Helmet>
        <title>New Foreground Asset | {config.appName}</title>
      </Helmet>
      <GridContainer>
        {(loading || uploading) && <LoadingSpinner opacity={uploading ? 1 : 0.2} />}
        {!loading && (<GridItem xs={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={9} lg={6}>
              <Box px={2} py={1} className={classes.titleBox}>
                <h3 className={classes.title}>
                  New Foreground Asset
                </h3>
              </Box>
              <Card>
                <CardBody>
                  <ValidatorForm onSubmit={e => {
                    e.preventDefault();

                    if (!asset.category_id) {
                      setCategoryError(true);
                    }

                    if (!file.name) {
                      setFileError(true);
                      setFileErrorText("You must select asset.");
                      return;
                    }

                    if (!thumb.name) {
                      setFileError(true);
                      setFileErrorText("You must select thumbnail.");
                      return;
                    }

                    let formData = new FormData();
                    formData.append("type", "upload_foreground_asset");
                    formData.append("category_id", asset.category_id);
                    formData.append("title", asset.title);
                    formData.append("tag", tag);
                    if (file && file !== {})
                      formData.append("fileToUpload", file);

                    if (thumb && thumb !== {})
                      formData.append("thumbFileToUpload", thumb);

                    setUploading(true);
                    apiService(config.api_url, "POST", formData, function(response) {
                      setUploading(false);
                      setOpenModal(true);
                      setAssetId(response.assetId);
                    }, function(err) {
                      setUploading(false);
                      console.log(err);
                    })
                  }}>
                    <Box py={1}>
                      <TextValidator 
                        name="title"
                        label="Title"
                        value={asset.title ? asset.title : ""}
                        variant="outlined"
                        fullWidth={true}
                        className={classes.input}
                        validators={['required']}
                        errorMessages={['This field is required']}
                        onChange={e => {
                          asset.title = e.target.value;
                          setAsset({...asset});
                        }}
                        error={fileError}
                        helperText={fileError ? fileErrorText : ""}
                      />
                    </Box>
                    <Box py={1}>
                      <TextField
                        select
                        value={asset.category_id ? parseInt(asset.category_id) : 0}
                        className={classes.selectType}
                        label="Category"
                        variant="outlined"
                        fullWidth={true}
                        onChange={e => {
                          asset.category_id = e.target.value;
                          setAsset({...asset});
                          setCategoryError(false);
                        }}
                        error={categoryError}
                        helperText={categoryError ? "Please select category" : ""}
                      >
                        <MenuItem aria-label="None" value={0} >Select Type</MenuItem>
                        {categoryList.map((category, key) => {
                          return <MenuItem aria-label="None" value={parseInt(category.id)} key={key} >{category.category}</MenuItem>
                        })}
                      </TextField>
                    </Box>
                    <Box py={1} className={classes.flexBox}>
                      <Link
                        href="#"
                        component="label"
                      >
                        Select Asset<input
                          type="file"
                          id="attachFile"
                          hidden
                          onChange={e => {
                            setFile(e.target.files[0]);
                          }}
                          name="attachments"
                          accept="image/gif, image/png, image/apng"
                        />
                      </Link>
                      {(file !== {}) && <p>
                        {file.name}
                      </p>}
                    </Box>
                    <Box py={1} className={classes.flexBox}>
                      <Link
                        href="#"
                        component="label"
                      >
                        Select Thumbnail<input
                          type="file"
                          id="thumbnail"
                          hidden
                          onChange={e => {
                            setThumb(e.target.files[0]);
                          }}
                          name="attachments"
                          accept="image/png"
                        />
                      </Link>
                      {(thumb !== {}) && <p>
                        {thumb.name}
                      </p>}
                    </Box>
                    <Box py={1}>
                      <TextField
                        value={tag}
                        label="Tag"
                        variant="outlined"
                        fullWidth={true}
                        onChange={e => {
                          setTag(e.target.value);
                        }}
                      />
                    </Box>
                    <Box py={1} className={classes.rightButton}>
                      <Button type="submit" size="lg">
                        Add
                      </Button>
                    </Box>
                  </ValidatorForm>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
          <Modal
            className={classes.modal}
            open={openModal}
            closeAfterTransition
            disableBackdropClick={true}
            disableEscapeKeyDown={true}>
            <Fade in={openModal}>
              <div className={classes.paper}>
                <h3 id="transition-modal-title">Added New Foreground Asset</h3>
                <Box className={classes.twoButton}>
                  <Button size="lg" onClick={e => {
                    props.history.push("/dashboard/foregroundasset/view/" + assetId);
                  }}>
                    Go to view
                  </Button>
                  <Button size="lg" onClick={e => {
                    setOpenModal(false);
                    setAsset({});
                    setAssetId("");
                    setTag("");
                  }}>
                    Continue to add
                  </Button>
                </Box>
              </div>
            </Fade>
          </Modal>
        </GridItem>)}
      </GridContainer>
    </div>
  )
}