import React, {useState} from "react";
import {Helmet} from "react-helmet";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import IconButton from '@material-ui/core/IconButton';
import CreateIcon from '@material-ui/icons/Create';
import SyncIcon from '@material-ui/icons/Sync';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import GetAppIcon from '@material-ui/icons/GetApp';
import ReplyIcon from '@material-ui/icons/Reply';
import Checkbox from '@material-ui/core/Checkbox';

import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Link from "@material-ui/core/Link";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';

import { authService } from "services/authService.js";
import apiService from "services/apiService.js";
import config from "constants/config.js";

import {utils} from "utils/index.js";
import LoadingSpinner from "components/Loading/Loading.js";

import styles from "assets/jss/material-dashboard-pro-react/views/viewAdminStyle.js";

const useStyles = makeStyles(styles);

export default function ViewAdmin(props) {
  const classes = useStyles();

  const [loading, setLoading] = useState(true);

  const [user, setUser] = useState({});

  const [editProfile, setEditProfile] = useState(false);
  const [superAdmin, setSuperAdmin] = useState(false);

  const adminId = props.match.params.id;

  React.useEffect(() => {
    setLoading(true);
    apiService(config.endpoint.viewAdmin.replace("adminId", adminId), "GET", {}, function(response) {
      setLoading(false);
      setUser({...response.user});
      setSuperAdmin(response.user.superAdmin);
    }, function(err) {
      setLoading(false);
    }, authService.getToken());

    return () => {

    }
  }, []);

  return (
    <div>
      <Helmet>
        <title>View Admin | AltExchange</title>
      </Helmet>
      <GridContainer>
        {loading && <LoadingSpinner />}
        {!loading && (<GridItem xs={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={9} lg={9}>
              <Box px={2} py={1} className={classes.flexBox}>
                <h3 className={classes.title}>
                  Admin Profile / {user.firstName + " " + user.lastName}
                </h3>
                <Box className={classes.rightButton}>
                  <Button size="lg" onClick={e => {
                    setLoading(true);
                    apiService(config.endpoint.deactivateAdmin.replace("userId", adminId), "PUT", {
                      active: !user.active
                    }, function(response) {
                      setLoading(false);
                      user.active = !user.active;
                      setUser({...user});
                    }, function(err) {
                      setLoading(false);
                      console.log(err);
                    }, authService.getToken())
                  }}>
                    {user.active ? "Deactivate User" : "Activate User"}
                  </Button>
                  <Button size="lg" onClick={e => {
                    setLoading(true);
                    apiService(config.endpoint.verifyAdmin.replace("userId", adminId), "GET", {}, function(res) {
                      setLoading(false);
                    }, function(err) {
                      setLoading(false);
                      console.log(err);
                    }, authService.getToken())
                  }}>
                    Send Activation Email
                  </Button>
                </Box>
              </Box>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={9} lg={6}>
              <Card className={classes.profileCard}>
                <CardBody>
                  {!editProfile && <Box className={classes.rightAlign}>
                    <IconButton onClick={e => {
                      setEditProfile(!editProfile);
                    }} title="Edit Admin Profile">
                      <CreateIcon />
                    </IconButton>
                  </Box>}
                  <ValidatorForm onSubmit={e => {
                    e.preventDefault();
                    const data = {
                      firstName: user.firstName,
                      lastName: user.lastName,
                      email: user.email,
                      superAdmin
                    };

                    apiService(config.endpoint.viewAdmin.replace("adminId", adminId), "PUT", data, function(response) {
                      setLoading(false);
                      // console.log(response);
                      setEditProfile(false);
                    }, function(err) {
                      console.log(err);
                      setLoading(false);
                    }, authService.getToken());
                  }}>
                    <Box py={1}>
                      <TextValidator 
                        name="firstName"
                        label="First Name"
                        value={user.firstName ? user.firstName : ""}
                        variant="outlined"
                        fullWidth={true}
                        className={classes.input}
                        validators={['required']}
                        errorMessages={['This field is required']}
                        onChange={e => {
                          user.firstName = e.target.value;
                          setUser({...user});
                        }}
                        disabled={!editProfile}
                      />
                    </Box>
                    <Box py={1}>
                      <TextValidator 
                        name="lastName"
                        label="last Name"
                        value={user.lastName ? user.lastName : ""}
                        variant="outlined"
                        fullWidth={true}
                        className={classes.input}
                        validators={['required']}
                        errorMessages={['This field is required']}
                        onChange={e => {
                          user.lastName = e.target.value;
                          setUser({...user});
                        }}
                        disabled={!editProfile}
                      />
                    </Box>
                    <Box py={1}>
                      <TextValidator 
                        name="email"
                        label="Email"
                        value={user.email ? user.email : ""}
                        variant="outlined"
                        fullWidth={true}
                        className={classes.input}
                        validators={['required', 'isEmail']}
                        errorMessages={['This field is required', 'Email is not valid.']}
                        onChange={e => {
                          user.email = e.target.value;
                          setUser({...user});
                        }}
                        disabled
                      />
                    </Box>
                    <Box>
                      <p>Status: {user.status ? "Confirmed email" : "Not confirmed email"}</p>
                    </Box>
                    <Box>
                      <p>
                        Joined at: {utils.getFormatDate(user.createdAt)}
                      </p>
                    </Box>
                    <Box>
                      <Checkbox
                        checked={superAdmin}
                        onChange={e => {
                          setSuperAdmin(e.target.checked);
                        }}
                        name="checkedB"
                        color="primary"
                        disabled={!editProfile}
                      />Super Admin
                    </Box>
                    {editProfile && <Box py={1} className={classes.rightButton}>
                      <Button size="lg" type="submit">
                        Update
                      </Button>
                      <Button size="lg" onClick={e => {
                        setEditProfile(false);
                      }}>
                        Cancel
                      </Button>
                    </Box>}
                  </ValidatorForm>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </GridItem>)}
      </GridContainer>
    </div>
  );
}
