import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import Button from "components/CustomButtons/Button.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import styles from "assets/jss/material-dashboard-pro-react/components/overlayStyle.js";
import { authService } from "services/authService.js";
import apiService from "services/apiService.js";
import config from "constants/config.js";

const loadingIcon = require("assets/img/loading.gif");

const useStyles = makeStyles(styles);

export default function Overlay(props) {
  const classes = useStyles();
  const {accountStatus, accountId} = props;
  return (
    <div className={classes.overlay}>
	    <div>
	      <p>
	        Get a consolidated view of your all your investments
	      </p>
	      {(accountStatus === "activateAccount") && <Button onClick={e => {
	      	apiService(config.endpoint.addAccount + "/" + accountId + "/signnow", "POST", {}, function(response) {
		        props.history.push("/dashboard/activate/" + accountId + "?helloSign=" + response.embeddedSigningUrl);
		      }, function(error) {
		        console.log(error);
		      }, authService.getToken());
	      }}>
	        Complete Account Setup
	      </Button>}
	      {(accountStatus === "addInvestment") && <Button onClick={e => {
	      	const newInvestmentBtn = document.getElementById("newInvestment");
	      	newInvestmentBtn.click();
	      }}>
	        Add Your First Investment
	      </Button>}
	    </div>
	  </div>
  );
}