import React, {useState} from "react";
import {Helmet} from "react-helmet";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import IconButton from '@material-ui/core/IconButton';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import { connect, compose, useSelector, useStore } from 'react-redux';

import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import Checkbox from '@material-ui/core/Checkbox';
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";

import Overlay from "components/Overlay/Overlay.js";

import { authService } from "services/authService.js";
import apiService from "services/apiService.js";
import config from "constants/config.js";

import {utils} from "utils/index.js";
import LoadingSpinner from "components/Loading/Loading.js";

import styles from "assets/jss/material-dashboard-pro-react/views/investorsStyle.js";

import MUIDataTable from "mui-datatables";

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

const useStyles = makeStyles(styles);
const emptyIcon = require("assets/img/emptyData.svg");

export default function ForegroundCategories(props) {
  const classes = useStyles();

  const [overView, setOverView] = useState({});

  const [chartData, setChartData] = useState({});
  const [accountId, setAccountId] = useState("");

  const store = useStore();
  const { account } = store.getState().accountInfo;

  const [loading, setLoading] = useState(true);

  const [chartOptions, setChartOptions] = useState({});

  const [investmentId, setInvestmentId] = useState(0);
  const [typeId, setTypeId] = useState(0);

  const [typeData, setTypeData] = useState([]);
  const [investmentsArr, setInvestmentsArr] = useState([]);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [updateArr, setUpdateArr] = useState([]);

  const getMuiTheme = () => createMuiTheme({
    overrides: {
      MUIDataTableBodyRow: {
        root: {
          cursor: 'pointer'
        }
      }
    }
  });

  React.useEffect(() => {
    setLoading(true);
    apiService(config.api_url + "?type=get_foreground_category", "POST", {}, function(response) {
      setLoading(false);
      setUpdateArr(response.data);
    }, function(err) {
      setLoading(false);
      console.log(err);
    })
  }, [])

  const columns = [
    {
      name: 'id',
      label: "Id",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return parseInt(value);
        },
        sortCompare: (order) => {
          return (obj1, obj2) => {
            console.log(order);
            let val1 = parseInt(obj1.data, 10);
            let val2 = parseInt(obj2.data, 10);
            return (val1 - val2) * (order === 'asc' ? 1 : -1);
          };
        }
      }
    },
    {
      name: 'category',
      label: 'Category'
    },
    {
      name: 'thumb_url',
      label: 'Thumbnail URL',
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return <Link href={updateArr[dataIndex].thumb_url} target="_blank" onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            window.open(updateArr[dataIndex].thumb_url, "_blank");
          }}>
            {updateArr[dataIndex].thumb_url}
          </Link>
        }
      }
    },
    {
      name: 'date',
      label: 'Created At',
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return utils.getFormatDate(updateArr[dataIndex].date);
        }
      }
    }
  ];

  const options = {
    selectableRows: 'none',
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    onRowClick: function(rowData, rowMeta) {
      props.history.push('/dashboard/foregroundcategory/view/' + updateArr[rowMeta.dataIndex].id);
    },
    customToolbar: function(data) {
      return (<React.Fragment>
        <IconButton aria-label="expand row" onClick={() => {
          props.history.push("/dashboard/foregroundcategory/new");
        }}>
          <AddCircleOutlineIcon />
        </IconButton>
      </React.Fragment>)
    }
  }

  return (
    <div>
      <Helmet>
        <title>Foreground Categories | {config.appName}</title>
      </Helmet>
      <GridContainer>
        {loading && <LoadingSpinner />}
        {!loading && (<GridItem xs={12}>
          <Box px={2} py={2}>
            <h4 className={classes.title}>
              Foreground Categories
            </h4>
          </Box>
          <MuiThemeProvider theme={getMuiTheme()}>
            <MUIDataTable
              data={updateArr}
              columns={columns}
              options={options} 
              className={classes.dataTable} />
          </MuiThemeProvider>
        </GridItem>)}
      </GridContainer>
    </div>
  );
}
