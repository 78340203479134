import config from "constants/config.js";
import {authService} from "services/authService.js";

const apiService = function(endpoint, method, data=undefined, callback, errback, token="") {
  fetch(endpoint, {
    method: method,
    body: data
  }).then(async(response) => {
    if (response.status !== 200) {
        const errData = await response.json();

        if (errData.message) {
          alert(errData.message);
        }
        return errback(errData);
      };
      const jsonData = await response.json();
      if (jsonData.status !== "Success") {
        return errback(jsonData);
      }
      return callback(jsonData);
  }).catch(err => {
    return errback(err);
  })
}

export default apiService;