/*!

=========================================================
* Material Dashboard PRO React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import AuthLayout from "layouts/Auth.js";
import RtlLayout from "layouts/RTL.js";
import AdminLayout from "layouts/Admin.js";

import { authService } from "services/authService.js";

import "assets/scss/material-dashboard-pro-react.scss?v=1.9.0";

import { Provider } from 'react-redux';
import store from './redux/store'

const hist = createBrowserHistory();

const AuthGuards = (props) => {
	const user = authService.getUser();
	if (user && props.children && props.location.pathname === "/") {
		return <Redirect to={'/dashboard/investors'}/>
	} else if (user && props.children) {
		return props.children;
	}

	return <Redirect to={'/admin/login'}/>;
}

ReactDOM.render(
	<Provider store={store}>
	  <Router history={hist}>
	    <Switch>
	      <Route path="/rtl" component={RtlLayout} />
	      <Route path="/admin" component={AuthLayout} />
	      <AuthGuards>
	      	<Route path="/dashboard" component={AdminLayout} />
	      </AuthGuards>
	      <Redirect from="/" to="/admin/login" />
	    </Switch>
	  </Router>
	</Provider>,
  document.getElementById("root")
);
