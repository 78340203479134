import { BehaviorSubject } from 'rxjs';
// import apiService from "services/apiService.js";
import config from 'constants/config.js'

class AuthService {

    credentials = {
        user: null,
        token: null
    }

    constructor() {
        // console.log(this.credentials);
        this.deserializeCredentials();

        // if (this.credentials.token !== null && this.getUser() !== null) {
        //     this.loadUserData();
        // }
    }

	setCredentials = (user, token, remember = true) => {
        this.credentials.user = user;
        this.credentials.token = user.token || token;
        const storage = remember ? localStorage : sessionStorage;
        storage.setItem("credentials", JSON.stringify(this.credentials));
    }

    deserializeCredentials = () => {
        if (this.credentials.user == null) {
            // Check the store
            let savedCredentials = localStorage.getItem("credentials") || sessionStorage.getItem("credentials");
            if (savedCredentials) {
                savedCredentials = JSON.parse(savedCredentials);
                this.setCredentials(savedCredentials.user, savedCredentials.token);
            }
        }
    }

    // loadUserData = () => {
    //     // console.log(this);
    //     const _this = this;
    //     const requestOptions = {
    //         method: "GET",
    //         headers: {
    //             'Accept': '*/*',
    //             'Content-Type': 'application/json',
    //             'Authorization': 'Bearer ' + _this.getToken()
    //         },
    //     }
    //     fetch(config.api_url + config.endpoint.viewAdmin.replace("adminId", _this.getUser().id), requestOptions)
    //         .then(async(response) => {
    //             if (response.status !== 200) {
    //                 const errData = await response.json();
    //                 _this.logout();
    //                 return;
    //             };
    //             const jsonData = await response.json();
    //         }).catch(err => {
    //             _this.logout();
    //         })
    // }

    updateUser = (user) => {
        this.setCredentials(user, this.credentials.token);
    }

    getToken = () => {
    	this.deserializeCredentials();
    	return this.credentials.token;
    }

    getUser = () => {
        this.deserializeCredentials();
        return this.credentials.user;
    }

    isAuthenticated = () => {
        this.deserializeCredentials();
        return this.credentials.user != null;
    }

    logout = (remember = true) => {
    	const storage = remember ? localStorage : sessionStorage;
    	this.credentials = {
    		user: null,
    		token: null
    	};
    	storage.removeItem("credentials");
        document.location.href = "/";
    }
}

export const authService = new AuthService();