import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import styles from "assets/jss/material-dashboard-pro-react/components/loadingStyle.js";

const loadingIcon = require("assets/img/loading.gif");

const useStyles = makeStyles(styles);

export default function LoadingSpinner(props) {
  const classes = useStyles();
  const { opacity } = props;
  return (
    <div className={classes.loading + (opacity ? " transparent" : "")} >
      <img src={loadingIcon} alt="loading" key={"loading"} />
    </div>
  );
}