const InvestorsStyle = {
	titleBar: {
		alignItems: "center",
		"@media (min-width: 1024px)": {
			marginBottom: "20px"
		},
		paddingTop: "30px"
	},
	title: {
		fontFamily: "Fira Sans",
		fontWeight: 600,
		fontSize: "24px",
		lineHeight: "29px",
		color: "#2A4266",
		marginBottom: 0,
		"@media (max-width: 1024px)": {
			marginBottom: "20px"
		}
	},
	controlBox: {
		"&>div": {
			width: "22%",
			marginRight: "1%",
			padding: "0 10px"
		},
		"&>div:first-child": {
			width: "25%",
			marginRight: "2%"
		},
		"&>div:nth-child(2)": {
			width: "25%",
			marginRight: "2%"
		},
		"@media (max-width: 1023px)": {
			"&>div": {
				width: "23%",
				marginRight: "2%"
			},
			"&>div:first-child": {
				width: "23%",
				marginRight: "2%"
			},
			"&>div:nth-child(2)": {
				width: "23%",
				marginRight: "2%"
			},
		},
		"@media (max-width: 767px)": {
			"&>div": {
				width: "50%",
				padding: "0 15px",
				marginBottom: "20px",
				marginRight: 0
			},
			"&>div:first-child": {
				width: "100%",
				marginRight: "0%",
			},
			"&>div:nth-child(2)": {
				width: "100%",
				marginRight: "0%"
			},
		},
		"& input": {
			fontSize: "14px",
			fontFamily: "Open Sans",
			fontWeight: "normal",
			lineHeight: "19px",
			color: "#434343"
		},
		"& .MuiOutlinedInput-input": {
			padding: "5px 14px"
		},
		alignItems: "flex-end"
	},
	selectType: {
		fontSize: "14px",
		"& .MuiSelect-selectMenu": {
			fontSize: "14px",
			fontFamily: "Open Sans",
			lineHeight: "19px",
			fontWeight: "normal",
			color: "#434343"
		},
		"& .MuiSelect-icon": {
			color: "#2A4266"
		},
		fontFamily: "Open Sans",
		lineHeight: "19px",
		fontWeight: "normal",
		color: "#434343"
	},
	dataTable: {
		"& .MuiToolbar-regular": {
			// display: "none"
		},
		"& .mui-row-selected": {
			backgroundColor: "rgba(237, 195, 12, 0.25)"
		},
		"& .MuiTableRow-root.Mui-selected, .MuiTableRow-root.Mui-selected:hover": {
			backgroundColor: "rgba(237, 195, 12, 0.25)"
		},
		"& .MuiTableHead-root": {
			backgroundColor: "#D3E1F9",
		},
	},
	MUIDataTableHeaderCell: {
		backgroundColor: "inherit"
	},
	selectedDownload: {
		paddingRight: "30px"
	},
	emptyBox: {
		textAlign: "center",
		"& p": {
			fontFamily: "Fira Sans",
			fontWeight: 600,
			fontSize: "18px",
			lineHeight: "22px",
			margin: "30px auto 0",
			color: "#969696",
			width: "285px"
		}
	}
}

export default InvestorsStyle;