const getDateTime = function(isoString) {
  var date = new Date(isoString);
  var year = date.getFullYear();
  var monthArray = ['Jan', 'Feb', "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  var month = monthArray[date.getMonth()];
  var day = date.getDate();
  var hour = date.getHours();
  var minute = date.getMinutes();
  if (minute < 10) {
    minute = "0" + minute;
  }
  var am = "am";

  if (hour > 12) {
    hour = hour - 12;
    am = "pm";
  }

  return `${month} ${day}, ${year} ${hour}:${minute} ${am}`;

}

const isToday = function(isoString) {
  const today = new Date();
  var someDate = new Date(isoString);
  return someDate.getDate() == today.getDate() &&
    someDate.getMonth() == today.getMonth() &&
    someDate.getFullYear() == today.getFullYear()
}

const isTomorrow = function(isoString) {
  var someDate = new Date(isoString);
  const today = new Date();
  const date2 = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
  if (someDate.getFullYear() == date2.getFullYear() && someDate.getMonth() == date2.getMonth() && someDate.getDate() == date2.getDate()) {
    return true; // date2 is one day after date1.
  }
  return false;
}

const getFormatDate = function(str) {
  const date = new Date(str);
  return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`
}

const getDateNow = function() {
  const date = new Date();
  return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`
}

const getOnlyDate = function(isoString) {
  if (isToday(isoString))
    return "TODAY";
  if (isTomorrow(isoString)) 
    return "TOMORROW";
  var date = new Date(isoString);
  var monthArray = ['JAN', 'FEB', "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
  var month = monthArray[date.getMonth()];
  var day = date.getDate();

  return `${month} ${day}`;
}

const getRandomColor = function() {
  var letters = '0123456789ABCDEF'.split('');
  var color = '#';
  for (var i = 0; i < 6; i++ ) {
      color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

const localeStringDollar = function(str) {
  var value = parseInt(str);

  if (value < 0) {
    return "-$" + Math.abs(value).toLocaleString();
  }
  return "$" + value.toLocaleString();
}

const numberWithUnit = function(number) {
  if (Math.abs(number) < 1000)
    return number;
  if (Math.abs(number) < 1000000) {
    if (number / 1000 === Math.floor(number / 1000))
      return (number / 1000) + "k";
    else 
      return (number / 1000).toFixed(1) + "k";
  }
  else { 
    if (number / 1000000 === Math.floor(number / 1000000))
      return (number / 1000000) + "m";
    else 
      return (number / 1000000).toFixed(1) + "m"; 
  }
}

const hexToRgba = function(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  return result ? `rgba(${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(result[3], 16)},1)` : hex;
}

export const utils = {
  getDateTime,
  getOnlyDate,
  getDateNow,
  getRandomColor,
  localeStringDollar,
  getFormatDate,
  numberWithUnit,
  hexToRgba
}