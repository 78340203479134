import React from "react";
import {Helmet} from "react-helmet";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";

import Box from "@material-ui/core/Box";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import TextField from "@material-ui/core/TextField";
import config from "constants/config.js";
import apiService from "services/apiService.js";
import { authService } from "services/authService.js";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';

const useStyles = makeStyles(styles);

export default function LoginPage(props) {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  React.useEffect(() => {
    let id = setTimeout(function() {
      setCardAnimation("");
    }, 700);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });
  const classes = useStyles();

  const User = authService.getUser();
  
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const [loginError, setLoginError] = React.useState(false);

  const form = React.useRef(null);

  const loginSubmit = function(e) {
    e.preventDefault();
    e.stopPropagation();

    const data = {
      email,
      password,
      type: "admin_login"
    }

    let formData = new FormData();
    formData.append("type", "admin_login");
    formData.append("email", email);
    formData.append("password", password);

    apiService(config.api_url, "POST", formData, function(response) {
      console.log(response);

      if (response.status === "Success") {
        authService.setCredentials(response.user);
        props.history.push("/dashboard/accounts");
      } else {
        setLoginError(true);
      }
    }, function(err) {
      console.log(err);
    })
  }

  return (
    <div className={classes.container}>
      <Helmet>
        <title>Login | {config.appName}</title>
      </Helmet>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={6}>
          <ValidatorForm 
            onSubmit={loginSubmit}
            ref={form}
            >
            <Card login className={classes[cardAnimaton] + " " + classes.loginCard}>
              <CardHeader className={classes.cardHeader}>
                <h4 className={classes.cardTitle}>Welcome Back</h4>
                <p>Log in to get started</p>
              </CardHeader>
              <CardBody className={classes.cardBody}>
                <TextValidator 
                  id="email"
                  name="email"
                  label="Email"
                  variant="outlined"
                  fullWidth={true}
                  validators={['required', 'isEmail']}
                  errorMessages={['This field is required', 'Email is not valid']}
                  className={classes.input}
                  value={email}
                  onChange={e => {
                    setEmail(e.target.value);
                    setLoginError(false);
                  }}
                  />
                <TextField 
                  id="password"
                  name="password"
                  type="password"
                  label="Password"
                  variant="outlined"
                  fullWidth={true}
                  className={classes.input}
                  value={password}
                  onChange={e => {
                    setPassword(e.target.value);
                    setLoginError(false);
                  }}
                  />
              </CardBody>
              <CardFooter className={classes.cardFooter}>
                <GridContainer justify="center">
                  {(loginError) && (<GridItem xs={12}>
                    <p className="text-danger">
                      Incorrect email or password. Please try again.
                    </p>
                  </GridItem>)}
                  <GridItem xs={12}>
                    <Button size="lg" type="submit" className={classes.loginButton}>
                      Log In
                    </Button>
                  </GridItem>
                </GridContainer>
              </CardFooter>
            </Card>
          </ValidatorForm>
        </GridItem>
      </GridContainer>
    </div>
  );
}
