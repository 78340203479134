import Buttons from "views/Components/Buttons.js";
import ErrorPage from "views/Pages/ErrorPage.js";
import Icons from "views/Components/Icons.js";
import LoginPage from "views/Pages/Authentication/LoginPage.js";

import ForgotPasswordPage from "views/Pages/Authentication/ForgotPasswordPage.js";
import ResetPasswordPage from "views/Pages/Authentication/ResetPasswordPage.js";
import EmailVerificationPage from "views/Pages/Authentication/EmailVerificationPage.js";

import Accounts from "views/Accounts/Accounts.js";
import ViewAccount from "views/Accounts/ViewAccount.js";
import NewAccount from "views/Accounts/NewAccount.js";
import MultiAccount from "views/Accounts/MultiAccount.js";

import Categories from "views/Categories/Categories.js";
import ViewCategory from "views/Categories/ViewCategory.js";
import NewCategory from "views/Categories/NewCategory.js";

import Admins from "views/Admin/Admins.js";
import NewAdmin from "views/Admin/NewAdmin.js";
import ViewAdmin from "views/Admin/ViewAdmin.js";

import Assets from "views/Assets/Assets.js";
import NewAsset from "views/Assets/NewAsset.js";
import MultipleAssets from "views/Assets/MultipleAssets.js";
import ViewAsset from "views/Assets/ViewAsset.js";

import ForegroundAssets from "views/ForegroundAssets/ForegroundAssets.js";
import NewForegroundAsset from "views/ForegroundAssets/NewForegroundAsset.js";
import ViewForegroundAsset from "views/ForegroundAssets/ViewForegroundAsset.js";

import ForegroundCategories from "views/ForegroundCategory/ForegroundCategories.js";
import NewForegroundCategory from "views/ForegroundCategory/NewForegroundCategory.js";
import ViewForegroundCategory from "views/ForegroundCategory/ViewForegroundCategory.js";

import MusicAssets from "views/MusicAssets/MusicAssets.js";
import NewMusicAsset from "views/MusicAssets/NewMusicAsset.js";
import ViewMusicAsset from "views/MusicAssets/ViewMusicAsset.js";

import MusicCategories from "views/MusicCategory/MusicCategories.js";
import NewMusicCategory from "views/MusicCategory/NewMusicCategory.js";
import ViewMusicCategory from "views/MusicCategory/ViewMusicCategory.js";

import ExperienceCategories from "views/ExperienceCategory/ExperienceCategories.js";
import NewExperienceCategory from "views/ExperienceCategory/NewExperienceCategory.js";
import ViewExperienceCategory from "views/ExperienceCategory/ViewExperienceCategory.js";

import SmallAssets from "views/SmallAssets/SmallAssets.js";
import NewSmallAsset from "views/SmallAssets/NewSmallAsset.js";
import ViewSmallAsset from "views/SmallAssets/ViewSmallAsset.js";
import MultiSmallAssets from "views/SmallAssets/MultiSmallAssets.js";

import ExperienceAssets from "views/Experience/ExperienceAssets.js";
import NewExperienceAsset from "views/Experience/NewExperienceAsset.js";
import ViewExperienceAsset from "views/Experience/ViewExperienceAsset.js";

var dashRoutes = [
  {
    path: "/accounts",
    name: "Customers",
    component: Accounts,
    layout: "/dashboard"
  },
  {
    path: "/account/view/:id",
    name: "View Customer",
    component: ViewAccount,
    hiddenMenu: true,
    layout: "/dashboard"
  },
  {
    path: "/account/new",
    name: "New Customer",
    component: NewAccount,
    hiddenMenu: true,
    layout: "/dashboard"
  },
  {
    path: "/account/multi",
    name: "Multiple Customers",
    component: MultiAccount,
    hiddenMenu: true,
    layout: "/dashboard"
  },
  {
    path: "/assets",
    name: "Background(iOS)",
    component: Assets,
    layout: "/dashboard",
  },
  {
    path: "/asset/view/:id",
    name: "View Asset",
    component: ViewAsset,
    hiddenMenu: true,
    layout: "/dashboard",
  },
  {
    path: "/asset/new",
    name: "New Asset",
    component: NewAsset,
    hiddenMenu: true,
    layout: "/dashboard",
  },
  {
    path: "/asset/multi",
    name: "Multi Assets",
    component: MultipleAssets,
    hiddenMenu: true,
    layout: "/dashboard",
  },
  {
    path: "/categories",
    name: "Background Categories",
    component: Categories,
    layout: "/dashboard",
  },
  {
    path: "/category/view/:id",
    name: "View Category",
    component: ViewCategory,
    hiddenMenu: true,
    layout: "/dashboard",
  },
  {
    path: "/category/new",
    name: "New Category",
    component: NewCategory,
    hiddenMenu: true,
    layout: "/dashboard",
  },
  {
    path: "/foregroundassets",
    name: "Foreground",
    component: ForegroundAssets,
    layout: "/dashboard",
  },
  {
    path: "/foregroundasset/view/:id",
    name: "View ForegroundAsset",
    component: ViewForegroundAsset,
    hiddenMenu: true,
    layout: "/dashboard",
  },
  {
    path: "/foregroundasset/new",
    name: "New ForegroundAsset",
    component: NewForegroundAsset,
    hiddenMenu: true,
    layout: "/dashboard",
  },
  {
    path: "/foregroundcategories",
    name: "Foreground Categories",
    component: ForegroundCategories,
    layout: "/dashboard",
  },
  {
    path: "/foregroundcategory/view/:id",
    name: "View ForegroundCategory",
    component: ViewForegroundCategory,
    hiddenMenu: true,
    layout: "/dashboard",
  },
  {
    path: "/foregroundcategory/new",
    name: "New ForegroundCategory",
    component: NewForegroundCategory,
    hiddenMenu: true,
    layout: "/dashboard",
  },
  {
    path: "/musicassets",
    name: "Music",
    component: MusicAssets,
    layout: "/dashboard",
  },
  {
    path: "/musicasset/view/:id",
    name: "View MusicAsset",
    component: ViewMusicAsset,
    hiddenMenu: true,
    layout: "/dashboard",
  },
  {
    path: "/musicasset/new",
    name: "New MusicAsset",
    component: NewMusicAsset,
    hiddenMenu: true,
    layout: "/dashboard",
  },
  {
    path: "/musiccategories",
    name: "Music Categories",
    component: MusicCategories,
    layout: "/dashboard",
  },
  {
    path: "/musiccategory/view/:id",
    name: "View MusicCategory",
    component: ViewMusicCategory,
    hiddenMenu: true,
    layout: "/dashboard",
  },
  {
    path: "/musiccategory/new",
    name: "New MusicCategory",
    component: NewMusicCategory,
    hiddenMenu: true,
    layout: "/dashboard",
  },
  {
    path: "/smallassets",
    name: "Background Videos(Android)",
    component: SmallAssets,
    layout: "/dashboard",
  },
  {
    path: "/smallasset/view/:id",
    name: "View Small Asset",
    component: ViewSmallAsset,
    hiddenMenu: true,
    layout: "/dashboard",
  },
  {
    path: "/smallasset/new",
    name: "New Small Asset",
    component: NewSmallAsset,
    hiddenMenu: true,
    layout: "/dashboard",
  },
  {
    path: "/smallasset/multi",
    name: "Multi Small Asset",
    component: MultiSmallAssets,
    hiddenMenu: true,
    layout: "/dashboard",
  },
  {
    path: "/experienceassets",
    name: "Experience",
    component: ExperienceAssets,
    layout: "/dashboard",
  },
  {
    path: "/experienceasset/view/:id",
    name: "View Experience",
    component: ViewExperienceAsset,
    hiddenMenu: true,
    layout: "/dashboard",
  },
  {
    path: "/experienceasset/new",
    name: "New Experience",
    component: NewExperienceAsset,
    hiddenMenu: true,
    layout: "/dashboard",
  },
  {
    path: "/experiencecategories",
    name: "Experience Categories",
    component: ExperienceCategories,
    layout: "/dashboard",
  },
  {
    path: "/experiencecategory/view/:id",
    name: "View ExperienceCategory",
    component: ViewExperienceCategory,
    hiddenMenu: true,
    layout: "/dashboard",
  },
  {
    path: "/experiencecategory/new",
    name: "New ExperienceCategory",
    component: NewExperienceCategory,
    hiddenMenu: true,
    layout: "/dashboard",
  },
  {
    path: '/admins',
    name: "Admin",
    component: Admins,
    hiddenMenu: true,
    layout: "/dashboard"
  },
  {
    path: '/admin/new',
    name: "New Admin",
    component: NewAdmin,
    hiddenMenu: true,
    layout: "/dashboard"
  },
  {
    path: '/admin/view/:id',
    name: "View Admin",
    component: ViewAdmin,
    hiddenMenu: true,
    layout: "/dashboard"
  },
  {
    path: "/logout",
    name: "Logout",
    layout: "/dashboard",
    append: true,
  },
  {
    path: "/login",
    name: "Login Page",
    component: LoginPage,
    layout: "/admin"
  }
];
export default dashRoutes;
