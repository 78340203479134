export const updateTypeArray = typeArr => ({
	type: "Update Type Array",
  	typeArr,
});

const startState = {
	typeArr: []
}

export default (state = startState, action) => {
	switch (action.type) {
		case "Update Type Array": {
			return { typeArr: [...action.typeArr] };
		}
		default: 
			return state;
	}
}