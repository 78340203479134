import {
  container,
  cardTitle,
  whiteColor,
  mainColor,
  grayColor
} from "assets/jss/material-dashboard-pro-react.js";

const loginPageStyle = theme => ({
  container: {
    ...container,
    zIndex: "4",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "0"
    }
  },
  cardTitle: {
    ...cardTitle,
    color: mainColor,
    fontSize: "28px",
    fontFamily: "Fira Sans",
    fontWeight: 600,
    lineHeight: "36px"
  },
  textCenter: {
    textAlign: "center"
  },
  justifyContentCenter: {
    justifyContent: "center !important",
    textAlign: "center"
  },
  cardFooter: {
    textAlign: "center",
    paddingBottom: "4rem",
    fontFamily: "Open Sans",
    fontWeight: 400,
    "& .text-danger": {
      fontSize: "16px",
      color: "#f44336"
    }
  },
  customButtonClass: {
    "&,&:focus,&:hover": {
      color: whiteColor
    },
    marginLeft: "5px",
    marginRight: "5px"
  },
  inputAdornment: {
    marginRight: "18px"
  },
  inputAdornmentIcon: {
    color: grayColor[6]
  },
  loginCard: {
    "@media (min-width: 1024px)": {
      width: "452px"
    },
    margin: "0 auto"
  },
  loginButton: {
    textTransform: 'inherit',
    background: "#40B6A0",
    padding: "8px 70px",
    fontWeight: 500,
    fontSize: "16px",
    fontFamily: "Fira Sans",
    lineHeight: "19px"
  },
  forgotPassword: {
    textTransform: "inherit",
    color: "#434343",
    textDecoration: "underline"
  },
  cardHidden: {
    opacity: "0",
    transform: "translate3d(0, -60px, 0)"
  },
  cardHeader: {
    fontFamily: "Open Sans",
    "& p": {
      color: "#434343",
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: 400,
    }
  },
  cardBody: {
    padding: "0.9375rem 70px",
    "@media (max-width: 1024px)": {
      padding: "0.9375rem 15px"
    }
  },
  socialLine: {
    padding: "0.9375rem 0"
  },
  input: {
    margin: "15px 0",
    fontFamily: "Open Sans",
    "&:last-child": {
      marginBottom: "0"
    }
  }
});

export default loginPageStyle;
