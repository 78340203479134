import React, {useState} from "react";
import {Helmet} from "react-helmet";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import IconButton from '@material-ui/core/IconButton';
import CreateIcon from '@material-ui/icons/Create';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";

import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';

import { authService } from "services/authService.js";
import apiService from "services/apiService.js";
import config from "constants/config.js";

import {utils} from "utils/index.js";
import LoadingSpinner from "components/Loading/Loading.js";

import styles from "assets/jss/material-dashboard-pro-react/views/viewAccountStyle.js";

import MUIDataTable from "mui-datatables";

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

const useStyles = makeStyles(styles);

export default function ViewAccount(props) {
  const classes = useStyles();

  const [loading, setLoading] = useState(true);

  const [user, setUser] = useState({});

  const [account, setAccount] = useState({});
  const [username, setUsername] = useState("");
  const [typeArr, setTypeArr] = useState([]);

  const [editProfile, setEditProfile] = useState(false);

  const [investmentsList, setInvestmentsList] = useState([]);
  const [accountTypeError, setAccountTypeError] = useState(false);

  const AccountId = props.match.params.id;

  const getMuiTheme = () => createMuiTheme({
    overrides: {
      MUIDataTableBodyRow: {
        root: {
          cursor: 'pointer'
        }
      },
      MuiTableCell: {
        root: {
          textAlign: 'center'
        }
      },
      MUIDataTableHeadCell: {
        contentWrapper: {
          justifyContent: 'center'
        },
        toolButton: {
          marginRight: 0
        }
      },
    }
  });

  React.useEffect(() => {
    setLoading(true);
    apiService(config.api_url + "?type=get_customer_type", "POST", {}, function(typeRes) {
      setTypeArr([...typeRes.data]);
      apiService(config.api_url + "?type=view_customer&id=" + AccountId, "POST", {}, function(response) {
        setLoading(false);
        setAccount(response.data);
      }, function(err) {
        setLoading(false);
      });
    }, function(typeErr) {
      setLoading(false);
      console.log(typeErr);
    })
  }, [AccountId]);

  return (
    <div>
      <Helmet>
        <title>View Customer | {config.appName}</title>
      </Helmet>
      <GridContainer>
        {loading && <LoadingSpinner />}
        {!loading && (<GridItem xs={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={9} lg={6}>
              <Box px={2} py={2}>
                <h3>
                  View Customer
                </h3>
              </Box>
              <Card className={classes.profileCard}>
                <CardBody>
                  {!editProfile && <Box className={classes.rightAlign}>
                    <IconButton onClick={e => {
                      setEditProfile(!editProfile);
                    }} title="Edit User Name">
                      <CreateIcon />
                    </IconButton>
                    <IconButton onClick={e => {
                      let confirm = window.confirm("Are you sure to remove this customer?");
                      if (confirm) {
                        setLoading(true);
                        apiService(config.api_url + "?type=delete_customer&id=" + AccountId, "POST", {}, function(response) {
                          setLoading(false);
                          alert("You removed customer successfully!");
                          props.history.push("/dashboard/accounts");
                        }, function(err) {
                          setLoading(false);
                          alert("Occured error during remove customer.");
                        })
                      }
                    }} title="Delete customer">
                      <DeleteIcon />
                    </IconButton>
                  </Box>}
                  <ValidatorForm onSubmit={e => {
                    e.preventDefault();

                    if (account.type_id === "0" || account.type_id === 0) {
                      setAccountTypeError(true);
                      return;
                    }

                    let formData = new FormData();

                    formData.append("id", AccountId);
                    formData.append("type", "update_customer");
                    formData.append("email", account.email);
                    formData.append("type_id", account.type_id);
                    if (account.name)
                      formData.append("name", account.name);
                    if (account.address)
                      formData.append("address", account.address);
                    if (account.amount_cents)
                      formData.append("amount_cents", account.amount_cents);
                    if (account.first_pair)
                      formData.append("first_pair", account.first_pair);
                    if (account.second_pair)
                      formData.append("second_pair", account.second_pair);

                    setLoading(true);
                    apiService(config.api_url, "POST", formData, function(response) {
                      setLoading(false);
                      setEditProfile(false);
                      if (response.status !== "Success") {
                        alert("Occured error during update customer!")
                      }
                    }, function(err) {
                      setLoading(false);
                      console.log(err);
                    })
                  }}>
                      <Box py={1}>
                        <TextValidator 
                          name="email"
                          label="Email"
                          value={account.email ? account.email : ""}
                          variant="outlined"
                          fullWidth={true}
                          className={classes.input}
                          validators={['required', 'isEmail']}
                          errorMessages={['This field is required', 'Invalid email']}
                          onChange={e => {
                            account.email = e.target.value;
                            setAccount({...account});
                          }}
                          disabled={!editProfile}
                        />
                      </Box>
                      <Box py={1}>
                        <TextField
                          select
                          value={account.type_id ? parseInt(account.type_id) : 0}
                          className={classes.selectType}
                          label="Account Type"
                          variant="outlined"
                          fullWidth={true}
                          onChange={e => {
                            account.type_id = e.target.value;
                            setAccount({...account});
                            setAccountTypeError(false);
                          }}
                          error={accountTypeError}
                          helperText={accountTypeError ? "Please select account type" : ""}
                          disabled={!editProfile}
                        >
                          <MenuItem aria-label="None" value={0} >Select Type</MenuItem>
                          {typeArr.map((type, key) => {
                            return <MenuItem aria-label="None" value={parseInt(type.id)} key={key} >{type.type}</MenuItem>
                          })}
                        </TextField>
                      </Box>
                      <Box py={1}>
                        <TextField 
                          name="name"
                          label="Name"
                          value={account.name ? account.name : ""}
                          variant="outlined"
                          fullWidth={true}
                          className={classes.input}
                          onChange={e => {
                            account.name = e.target.value;
                            setAccount({...account});
                          }}
                          disabled={!editProfile}
                        />
                      </Box>
                      <Box py={1}>
                        <TextValidator 
                          name="address"
                          label="Address"
                          value={account.address ? account.address : ""}
                          variant="outlined"
                          fullWidth={true}
                          className={classes.input}
                          validators={['required']}
                          errorMessages={['This field is required']}
                          onChange={e => {
                            account.address = e.target.value;
                            setAccount({...account});
                          }}
                          disabled={!editProfile}
                        />
                      </Box>
                      <Box py={1}>
                        <TextValidator 
                          name="amount_cents"
                          label="Amount Cents"
                          value={account.amount_cents ? account.amount_cents : ""}
                          variant="outlined"
                          fullWidth={true}
                          className={classes.input}
                          validators={['required', 'isNumber']}
                          errorMessages={['This field is required', 'This field must be number.']}
                          onChange={e => {
                            account.amount_cents = e.target.value;
                            setAccount({...account});
                          }}
                          disabled={!editProfile}
                        />
                      </Box>
                      <Box py={1} sx={{
                        display: "flex",
                        justifyContent: "space-between"
                      }}>
                        <TextField 
                          name="first_pair"
                          label="First Pair"
                          value={account.first_pair ? account.first_pair : ""}
                          variant="outlined"
                          fullWidth={true}
                          className={classes.input}
                          onChange={e => {
                            account.first_pair = e.target.value;
                            setAccount({...account});
                          }}
                          disabled={!editProfile}
                        />
                        <TextField 
                          name="second_pair"
                          label="Second Pair"
                          value={account.second_pair ? account.second_pair : ""}
                          variant="outlined"
                          fullWidth={true}
                          className={classes.input}
                          onChange={e => {
                            account.second_pair = e.target.value;
                            setAccount({...account});
                          }}
                          disabled={!editProfile}
                        />
                      </Box>
                    {editProfile && <Box py={1} className={classes.rightButton}>
                      <Button size="lg" onClick={e => {
                        setEditProfile(false);
                      }}>
                        Cancel
                      </Button>
                      <Button size="lg" type="submit">
                        Update
                      </Button>
                    </Box>}
                  </ValidatorForm>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </GridItem>)}
      </GridContainer>
    </div>
  );
}
