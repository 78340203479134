/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import styles from "assets/jss/material-dashboard-pro-react/components/footerStyle.js";
import Hidden from "@material-ui/core/Hidden";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { fluid, white, rtlActive, logo, mLogo } = props;
  var container = cx({
    [classes.container]: !fluid,
    [classes.containerFluid]: fluid,
    [classes.whiteColor]: white
  });
  var anchor =
    classes.a +
    cx({
      [" " + classes.whiteColor]: white
    });
  var block = cx({
    [classes.block]: true,
    [classes.whiteColor]: white
  });

  const product = [
    'Product', 'Platform tour video', 'Overview', 'Aggregated Portfolio', 'Real-time reporting', 'Fee tracking', 'Backoffice support team', 'Capital call management', 'Benchmarks'
  ];

  const contact = [
    'Contact', 'Contact Us', 'Schedule a Demo', 'About Us'
  ];

  const education = [
    'Education', 'The AltX Blog', 'The AltX Show'
  ];

  const terms = [
    'Privacy Policy', 'Terms of Service', 'Investor Stories', 'Affiliate Program'
  ];
  return (
    <footer className={classes.footer}>
      <div className={classes.footerContainer}>
        <GridContainer item direction="row" xs={12}>
          <GridItem xs={6} sm={3} md={3}>
            <Hidden smDown>
              <div className={classes.footerLogo}>
                  <Button href="/" className={classes.logo} color="transparent">
                    <img src={logo}/>
                  </Button>
              </div>
            </Hidden>
            <h4>Track your alts like you do your stocks.</h4>
          </GridItem>
          <GridItem xs={false} sm={6} md={6}>
          </GridItem>
          <GridItem xs={6} sm={3} md={3}>
            <p className={classes.copyRight}>
              &copy; AltExchange, LLC {1900 + new Date().getYear()}
            </p>
          </GridItem>
        </GridContainer>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  fluid: PropTypes.bool,
  white: PropTypes.bool,
  rtlActive: PropTypes.bool
};
