import React from "react";
import {Helmet} from "react-helmet";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";

import Box from "@material-ui/core/Box";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";

import config from "constants/config.js";
import apiService from "services/apiService.js";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';

const useStyles = makeStyles(styles);

export default function ForgotPasswordPage(props) {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  React.useEffect(() => {
    let id = setTimeout(function() {
      setCardAnimation("");
    }, 700);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });
  const classes = useStyles();

  const [email, setEmail] = React.useState("");

  const [isSuccess, setIsSuccess] = React.useState(false);

  const form = React.useRef(null);

  const forgotPassword = function(e) {
    e.preventDefault();
    e.stopPropagation();

    const data = {
      email
    }

    apiService(config.endpoint.forgotPassword, "POST", data, function(response) {
      console.log(response);
      setIsSuccess(true);
    }, function(err) {

    })
    return;
  }

  return (
    <div className={classes.container}>
      <Helmet>
        <title>Forgot Password</title>
      </Helmet>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={6}>
          <ValidatorForm 
            onSubmit={forgotPassword}
            ref={form}
            >
            {!isSuccess && <Card login className={classes[cardAnimaton] + " " + classes.loginCard}>
              <CardHeader className={classes.cardHeader}>
                <h4 className={classes.cardTitle}>Forgot Password</h4>
              </CardHeader>
              <CardBody className={classes.cardBody}>
                <TextValidator 
                  id="email"
                  name="email"
                  label="Email"
                  placeholder="Email Address"
                  variant="outlined"
                  fullWidth={true}
                  validators={['required', 'isEmail']}
                  errorMessages={['This field is required', 'Email is not valid']}
                  className={classes.input}
                  value={email}
                  onChange={e => {
                    setEmail(e.target.value);
                  }}
                  />
              </CardBody>
              <CardFooter className={classes.cardFooter}>
                <GridContainer justify="center">
                  <GridItem xs={12}>
                    <Button size="lg" type="submit" className={classes.loginButton}>
                      Reset Password
                    </Button>
                  </GridItem>
                  <GridItem xs={12}>
                    <Box m={1}>
                      <Link href="/admin/login" onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        props.history.push('/admin/login');
                      }} className={classes.forgotPassword}>
                        Log In
                      </Link>
                    </Box>
                  </GridItem>
                </GridContainer>
              </CardFooter>
            </Card>}
            {isSuccess && (<Card login className={classes[cardAnimaton] + " " + classes.loginCard}>
              <CardHeader className={classes.cardHeader}>
                <h4 className={classes.cardTitle}>Thank you! You will receive a verification to reset your password.</h4>
              </CardHeader>
            </Card>)}
          </ValidatorForm>
        </GridItem>
      </GridContainer>
    </div>
  );
}
