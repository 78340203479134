import React, {useState} from "react";
import {Helmet} from "react-helmet";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import IconButton from '@material-ui/core/IconButton';
import CreateIcon from '@material-ui/icons/Create';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteIcon from '@material-ui/icons/Delete';

import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';

import { authService } from "services/authService.js";
import apiService from "services/apiService.js";
import config from "constants/config.js";

import {utils} from "utils/index.js";
import LoadingSpinner from "components/Loading/Loading.js";

import styles from "assets/jss/material-dashboard-pro-react/views/viewInvestmentStyle.js";

import MUIDataTable from "mui-datatables";

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

const useStyles = makeStyles(styles);

export default function MultipleAssets(props) {
  const classes = useStyles();

  const [loading, setLoading] = useState(true);

  const [categoryList, setCategoryList] = useState({});
  const [category, setCategory] = useState(0);
  const [files, setFiles] = useState([]);
  const [categoryError, setCategoryError] = useState(false);
  const [categoryErrorText, setCategoryErrorText] = useState("");

  const [uploading, setUploading] = useState(false);
  const [fileError, setFileError] = useState(false);
  const [fileErrorText, setFileErrorText] = useState("");

  const [openModal, setOpenModal] = useState(false);
  const [assetId, setAssetId] = useState("");
  
  React.useEffect(() => {
    apiService(config.api_url + "?type=get_category", "POST", {}, function(categoryRes) {
      setCategoryList([...categoryRes.data]);
      setLoading(false);
    }, function(categoryErr) {
      setLoading(false);
      console.log(categoryErr);
    })
  }, []);

  return (
    <div>
      <Helmet>
        <title>Multi Assets | {config.appName}</title>
      </Helmet>
      <GridContainer>
        {(loading || uploading) && <LoadingSpinner opacity={uploading ? 1 : 0.2} />}
        {!loading && (<GridItem xs={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={9} lg={6}>
              <Box px={2} py={1} className={classes.titleBox}>
                <h3 className={classes.title}>
                  Multi Assets
                </h3>
              </Box>
              <Card>
                <CardBody>
                  <ValidatorForm onSubmit={e => {
                    e.preventDefault();

                    if (category === "0" || category === 0) {
                      setCategoryError(true);
                      setCategoryErrorText("Please select category");
                      return;
                    }

                    if (files.length === 0) {
                      setCategoryError(true);
                      setCategoryErrorText("Please select assets");
                      return;
                    };

                    let formData = new FormData();

                    for (let i = 0; i < files.length; i ++) {
                      formData.append("fileToUpload[" + i + "]", files[i]);
                    };

                    formData.append("type", "create_multi_assets");
                    formData.append("category_id", category);

                    setUploading(true);
                    apiService(config.api_url, "POST", formData, function(response) {
                      setUploading(false);
                      alert("You've uploaded assets successfully!");
                      props.history.push("/dashboard/assets");
                    }, function(err) {
                      console.log(err);
                      setUploading(false)
                      alert("Occured error during upload assets. Please refresh page");
                    });

                  }}>
                    <Box py={1}>
                      <TextField
                        select
                        value={category}
                        className={classes.selectType}
                        label="Category"
                        variant="outlined"
                        fullWidth={true}
                        onChange={e => {
                          setCategory(e.target.value);
                          setCategoryError(false);
                        }}
                        error={categoryError}
                        helperText={categoryError ? categoryErrorText : ""}
                      >
                        <MenuItem aria-label="None" value={0} >Select Type</MenuItem>
                        {categoryList.map((category, key) => {
                          return <MenuItem aria-label="None" value={parseInt(category.id)} key={key} >{category.category}</MenuItem>
                        })}
                      </TextField>
                    </Box>
                    <Box py={1} className={classes.flexBox}>
                      <Link
                        href="#"
                        component="label"
                      >
                        Select Assets<input
                          type="file"
                          id="attachFile"
                          hidden
                          onChange={e => {
                            let tmp = [];

                            for (let i = 0; i < e.target.files.length; i ++) {
                              tmp.push(e.target.files[i]);
                            }

                            setFiles([...tmp]);
                          }}
                          name="attachments"
                          multiple
                        />
                      </Link>
                      
                    </Box>
                    {files.map((file, key) => {
                      return <p key={key}>
                        {file.name}
                      </p>
                    })}
                    <Box py={1} className={classes.rightButton}>
                      <Button type="submit" size="lg">
                        Add
                      </Button>
                    </Box>
                  </ValidatorForm>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </GridItem>)}
      </GridContainer>
    </div>
  )
}