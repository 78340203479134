import React, {useState} from "react";
import {Helmet} from "react-helmet";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";

import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';

import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';

import { authService } from "services/authService.js";
import apiService from "services/apiService.js";
import config from "constants/config.js";

import LoadingSpinner from "components/Loading/Loading.js";

import styles from "assets/jss/material-dashboard-pro-react/views/newInvestorStyle.js";

const useStyles = makeStyles(styles);

export default function NewAccount(props) {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);

  const [openModal, setOpenModal] = useState(false);

  const [accountTypeError, setAccountTypeError] = useState(false);

  const [userList, setUserList] = useState([]);
  const [typeArr, setTypeArr] = useState([]);

  const [account, setAccount] = useState({});
  const [accountId, setAccountId] = useState("");

  React.useEffect(() => {
    apiService(config.api_url + "?type=get_customer_type", "POST", {}, function(typeRes) {
      setTypeArr([...typeRes.data]);
      setLoading(false);
    }, function(typeErr) {
      setLoading(false);
      console.log(typeErr);
    })
  }, [])

  return (
    <div>
      <Helmet>
        <title>New Customer | {config.appName}</title>
      </Helmet>
      <GridContainer>
        {loading && <LoadingSpinner />}
        {!loading && (<GridItem xs={12}>
          <Box px={2} py={1}>
            <h3 className={classes.title}>
              New Customer
            </h3>
          </Box>
          <ValidatorForm onSubmit={e => {
            e.preventDefault();
            if (!account.type_id || account.type_id === "0" || account.type_id === 0) {
              setAccountTypeError(true);
              return;
            }

            let formData = new FormData();

            formData.append("type", "add_customer");
            formData.append("email", account.email);
            formData.append("type_id", account.type_id);
            if (account.name)
              formData.append("name", account.name);
            if (account.address)
              formData.append("address", account.address);
            if (account.amount_cents)
              formData.append("amount_cents", account.amount_cents);
            if (account.first_pair)
              formData.append("first_pair", account.first_pair);
            if (account.second_pair)
              formData.append("second_pair", account.second_pair);

            setLoading(true);
            apiService(config.api_url, "POST", formData, function(response) {
              setLoading(false);
              
              if (response.status !== "Success") {
                alert("Occured error during update customer!");
                return;
              }

              setAccountId(response.userId);
              setOpenModal(true);

            }, function(err) {
              setLoading(false);
              console.log(err);
            })
          }}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={9} lg={6}>
                <Card className={classes.profileCard}>
                  <CardBody>
                    <Box py={1}>
                      <TextValidator 
                        name="email"
                        label="Email"
                        value={account.email ? account.email : ""}
                        variant="outlined"
                        fullWidth={true}
                        className={classes.input}
                        validators={['required', 'isEmail']}
                        errorMessages={['This field is required', 'Invalid email']}
                        onChange={e => {
                          account.email = e.target.value;
                          setAccount({...account});
                        }}
                      />
                    </Box>
                    <Box py={1}>
                      <TextField
                        select
                        value={account.type_id ? parseInt(account.type_id) : 0}
                        className={classes.selectType}
                        label="Account Type"
                        variant="outlined"
                        fullWidth={true}
                        onChange={e => {
                          account.type_id = e.target.value;
                          setAccount({...account});
                          setAccountTypeError(false);
                        }}
                        error={accountTypeError}
                        helperText={accountTypeError ? "Please select account type" : ""}
                      >
                        <MenuItem aria-label="None" value={0} >Select Type</MenuItem>
                        {typeArr.map((type, key) => {
                          return <MenuItem aria-label="None" value={parseInt(type.id)} key={key} >{type.type}</MenuItem>
                        })}
                      </TextField>
                    </Box>
                    <Box py={1}>
                      <TextValidator 
                        name="name"
                        label="Name"
                        value={account.name ? account.name : ""}
                        variant="outlined"
                        fullWidth={true}
                        className={classes.input}
                        validators={['required']}
                        errorMessages={['This field is required']}
                        onChange={e => {
                          account.name = e.target.value;
                          setAccount({...account});
                        }}
                      />
                    </Box>
                    <Box py={1}>
                      <TextValidator 
                        name="address"
                        label="Address"
                        value={account.address ? account.address : ""}
                        variant="outlined"
                        fullWidth={true}
                        className={classes.input}
                        validators={['required']}
                        errorMessages={['This field is required']}
                        onChange={e => {
                          account.address = e.target.value;
                          setAccount({...account});
                        }}
                      />
                    </Box>
                    <Box py={1}>
                      <TextValidator 
                        name="amount_cents"
                        label="Amount Cents"
                        value={account.amount_cents ? account.amount_cents : ""}
                        variant="outlined"
                        fullWidth={true}
                        className={classes.input}
                        validators={['required', 'isNumber']}
                        errorMessages={['This field is required', 'This field must be number.']}
                        onChange={e => {
                          account.amount_cents = e.target.value;
                          setAccount({...account});
                        }}
                      />
                    </Box>
                    <Box py={1} sx={{
                      display: "flex",
                      justifyContent: "space-between"
                    }}>
                      <TextValidator 
                        name="first_pair"
                        label="First Pair"
                        value={account.first_pair ? account.first_pair : ""}
                        variant="outlined"
                        fullWidth={true}
                        className={classes.input}
                        validators={['required']}
                        errorMessages={['This field is required']}
                        onChange={e => {
                          account.first_pair = e.target.value;
                          setAccount({...account});
                        }}
                      />
                      <TextValidator 
                        name="second_pair"
                        label="Second Pair"
                        value={account.second_pair ? account.second_pair : ""}
                        variant="outlined"
                        fullWidth={true}
                        className={classes.input}
                        validators={['required']}
                        errorMessages={['This field is required']}
                        onChange={e => {
                          account.second_pair = e.target.value;
                          setAccount({...account});
                        }}
                      />
                    </Box>
                    <Box py={1} className={classes.rightButton}>
                      <Button size="lg" type="submit">
                        Add
                      </Button>
                    </Box>
                  </CardBody>
                </Card>
                    
              </GridItem>
            </GridContainer>
          </ValidatorForm>
          <Modal
            className={classes.modal}
            open={openModal}
            closeAfterTransition
            disableBackdropClick={true}
            disableEscapeKeyDown={true}>
            <Fade in={openModal}>
              <div className={classes.paper}>
                <h3 id="transition-modal-title">Added New Customer</h3>
                <Box className={classes.twoButton}>
                  <Button size="lg" onClick={e => {
                    props.history.push("/dashboard/account/view/" + accountId);
                  }}>
                    Go to view
                  </Button>
                  <Button size="lg" onClick={e => {
                    setOpenModal(false);
                    setAccount({});
                    setAccountId("");
                  }}>
                    Continue to add
                  </Button>
                </Box>
              </div>
            </Fade>
          </Modal>
        </GridItem>)}
      </GridContainer>
    </div>
  );
}
