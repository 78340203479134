import React, {useState} from "react";
import {Helmet} from "react-helmet";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";

import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import Checkbox from '@material-ui/core/Checkbox';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';

import { authService } from "services/authService.js";
import apiService from "services/apiService.js";
import config from "constants/config.js";

import LoadingSpinner from "components/Loading/Loading.js";

import styles from "assets/jss/material-dashboard-pro-react/views/newInvestorStyle.js";

const useStyles = makeStyles(styles);

export default function NewAdmin(props) {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [superAdmin, setSuperAdmin] = useState(true);

  const [openModal, setOpenModal] = useState(false);

  const [adminId, setAdminId] = useState("");

  return (
    <div>
      <Helmet>
        <title>New Admin | AltExchange</title>
      </Helmet>
      <GridContainer>
        {loading && <LoadingSpinner />}
        {!loading && (<GridItem xs={12}>
          <Box px={2} py={1}>
            <h3>
              New Admin
            </h3>
          </Box>
          <ValidatorForm onSubmit={e => {
            e.preventDefault();
            const data = {
              firstName,
              lastName,
              email,
              superAdmin
            }

            setLoading(true);
            apiService(config.endpoint.newAdmin, 'POST', data, function(response) {
              setLoading(false);
              console.log(response);
              setAdminId(response.user._id);
              setOpenModal(true);
            }, function(err) {
              setLoading(false);
            }, authService.getToken());
          }}>
            <GridContainer>
              <GridItem xs={12}>
                <GridContainer>
                  <GridItem sm={6} md={5}>
                    <Box px={2} py={1}>
                      <TextValidator 
                        name="firstName"
                        label="First Name"
                        value={firstName}
                        variant="outlined"
                        fullWidth={true}
                        className={classes.input}
                        validators={['required']}
                        errorMessages={['This field is required']}
                        onChange={e => {
                          setFirstName(e.target.value);
                        }}
                      />
                    </Box>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem sm={6} md={5}>
                    <Box px={2} py={1}>
                      <TextValidator 
                        name="lastName"
                        label="Last Name"
                        value={lastName}
                        variant="outlined"
                        fullWidth={true}
                        className={classes.input}
                        validators={['required']}
                        errorMessages={['This field is required']}
                        onChange={e => {
                          setLastName(e.target.value);
                        }}
                      />
                    </Box>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem sm={6} md={5}>
                    <Box px={2} py={1}>
                      <TextValidator 
                        name="email"
                        label="Email"
                        value={email}
                        variant="outlined"
                        fullWidth={true}
                        className={classes.input}
                        validators={['required', 'isEmail']}
                        errorMessages={['This field is required', 'Email is not valid']}
                        onChange={e => {
                          setEmail(e.target.value);
                        }}
                      />
                    </Box>
                    <Box px={2} py={1}>
                      <Checkbox
                        checked={superAdmin}
                        onChange={e => {
                          setSuperAdmin(e.target.checked);
                        }}
                        name="checkedB"
                        color="primary"
                      />Super Admin
                    </Box>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem sm={6} md={5}>
                    <Box px={2} py={1} className={classes.rightButton}>
                      <Button type="submit" size="lg">
                        Add
                      </Button>
                    </Box>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </ValidatorForm>
          <Modal
            className={classes.modal}
            open={openModal}
            closeAfterTransition
            disableBackdropClick={true}
            disableEscapeKeyDown={true}>
            <Fade in={openModal}>
              <div className={classes.paper}>
                <h3 id="transition-modal-title">Added New Admin.</h3>
                <Box className={classes.twoButton}>
                  <Button size="lg" onClick={e => {
                    props.history.push("/dashboard/admin/view/" + adminId);
                  }}>
                    Go to view
                  </Button>
                  <Button size="lg" onClick={e => {
                    setFirstName("");
                    setLastName("");
                    setEmail("");
                    setAdminId("");
                    setOpenModal(false);
                  }}>
                    Continue to add
                  </Button>
                </Box>
              </div>
            </Fade>
          </Modal>
        </GridItem>)}
      </GridContainer>
    </div>
  );
}
