import moment from 'moment';
// import { startState } from '../reducers/Checkout';

export const loadState = () => {
  try {
    const savedAt = localStorage.getItem('savedAt');
    if (!savedAt) {
      //resets any previous saved states before savedAt was introduced
      return undefined;
    }

    const fiveMinutesAgo = moment().subtract(5, 'minutes');
    const timeLastSaved = moment(savedAt);

    if (timeLastSaved < fiveMinutesAgo) {
      //reset who haven't changed / updated anything in 5 minutes.
      return undefined;
    }

    const serializedState = localStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    }
    const json = JSON.parse(serializedState);
    const today = moment(new Date());
    let moveDate = moment(json.moveDate, 'MM/DD/YYYY');
    if (moveDate < today) {
      moveDate = today;
    }

    return {
      ...json
    };
  } catch (err) {
    return undefined;
  }
};

export const saveState = state => {
  try {
    //remove anchors from state.
    const cleanedState = {
      ...state,
    };
    const serializedState = JSON.stringify(cleanedState);
    localStorage.setItem('state', serializedState);
    localStorage.setItem('savedAt', new Date().toISOString())
  } catch (err) {
    //Ignore write errors;
  }
};