const viewInvestmentStyle = {
	flexBox: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		"& p": {
			marginBottom: 0
		}
	},
	titleBox: {
		"& p": {
			marginTop: "15px"
		}
	},
	rightButton: {
		textAlign: "right",
		"& button": {
			backgroundColor: "#009F9B",
			textTransform: "inherit",
			color: "#fff",
			fontSize: "14px",
			padding: "8px 20px",
			borderRadius: "3px",
			margin: 0
		},
		"& button:last-child": {
			marginLeft: "15px"
		}
	},
	profileCard: {
		margin: "15px 0"
	},
	rightAlign: {
		textAlign: "right"
	},
	title: {
		fontWeight: "bold",
		margin: 0
	},
	subTitle: {
		fontSize: "20px",
		fontWeight: "bold"
	},
	transactionBox: {
		display: "flex",
		"& p": {
			width: "30%",
			marginBottom: 0
		},
		alignItems: "center"
	},
	threeButton: {
		textAlign: "right",
		"& button": {
			backgroundColor: "#009F9B",
			textTransform: "inherit",
			color: "#fff",
			fontSize: "14px",
			padding: "8px 20px",
			borderRadius: "3px",
			margin: 0
		},
		"& button:nth-child(2)": {
			margin: "0 10px"
		}
	},
	modal: {
	    display: 'flex',
	    alignItems: 'center',
	    justifyContent: 'center',
  	},
  	paper: {
  		backgroundColor: "#fff",
  		border: '1px solid #000',
  		padding: "20px 30px",
  		boxShadow: "0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%)"
  	},
  	twoButton: {
		textAlign: "right",
		"& button": {
			backgroundColor: "#009F9B",
			textTransform: "inherit",
			color: "#fff",
			fontSize: "14px",
			padding: "8px 20px",
			borderRadius: "3px",
			margin: 0,
			marginRight: "10px"
		}
	},
	status: {
		fontSize: "16px"
	},
	approveBtn: {
		backgroundColor: "#009F9B",
		textTransform: "inherit",
		color: "#fff",
		fontSize: "14px",
		padding: "8px 20px",
		borderRadius: "3px",
		margin: 0
	},
	modalTitle: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		"& p": {
			marginBottom: 0,
			fontSize: "18px"
		}
	}
}

export default viewInvestmentStyle;