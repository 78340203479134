import React, {useState} from "react";
import {Helmet} from "react-helmet";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import { authService } from "services/authService.js";
import apiService from "services/apiService.js";
import config from "constants/config.js";
import { utils } from "utils/index.js";

import LoadingSpinner from "components/Loading/Loading.js";

import styles from "assets/jss/material-dashboard-pro-react/views/investorsStyle.js";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import IconButton from "@material-ui/core/IconButton";
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import RemoveCircleOutlineOutlinedIcon from '@material-ui/icons/RemoveCircleOutlineOutlined';

import MUIDataTable from "mui-datatables";

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

const useStyles = makeStyles(styles);

export default function Accounts(props) {
  const classes = useStyles();

  const [loading, setLoading] = useState(true);

  const [updateArr, setUpdateArr] = useState([]);
  const [typeArr, setTypeArr] = useState([]);

  const getMuiTheme = () => createMuiTheme({
    overrides: {
      MUIDataTableBodyRow: {
        root: {
          cursor: 'pointer'
        }
      }
    }
  });

  React.useEffect(() => {
    setLoading(true);

    apiService(config.api_url + "?type=get_customer_type", "POST", {}, function(typeRes) {
      setTypeArr([...typeRes.data]);
      apiService(config.api_url + "?type=get_customers", "POST", {}, function(response) {
        setLoading(false);
        if (response.status === "Success") {
          setUpdateArr([...response.data]);
        }
      }, function(err) {
        setLoading(false);
        console.log(err);
      })
    }, function(typeErr) {
      setLoading(false);
      console.log(typeErr);
    })
      
  }, []);

  const getTypeName = (typeId) => {
    for (let i = 0; i < typeArr.length; i ++) {
      if (typeArr[i].id === typeId) 
        return typeArr[i].type;
    }
  }

  const columns = [
    {
      name: "id",
      label: "Id",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return parseInt(value);
        }
      }
    },
    {
      name: 'email',
      label: "Email",
    },
    {
      name: 'type_id',
      label: "Type",
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return getTypeName(updateArr[dataIndex].type_id)
        }
      }
    },
    {
      name: 'address',
      label: "Address"
    },
    {
      name: "name",
      label: "Name"
    },
    {
      name: "amount_cents",
      label: "Amount Cents"
    },
    {
      name: "first_pair",
      label: "First Pair"
    },
    {
      name: "second_pair",
      label: "Second Pair"
    },
    {
      name: "date",
      label: "Created At",
      customBodyRender: (dataIndex, rowIndex) => {
        return utils.getFormatDate(updateArr[dataIndex].date);
      }
    }
  ];

  const options = {
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    onRowClick: function(rowData, rowMeta) {
      window.open('/dashboard/account/view/' + updateArr[rowMeta.dataIndex].id, "_blank");
    },
    sort: false,
    customToolbar: function(data) {
      return (<React.Fragment>
        <IconButton aria-label="expand row" onClick={() => {
          props.history.push("/dashboard/account/new");
        }}>
          <AddCircleOutlineIcon />
        </IconButton>
        <IconButton aria-label="expand row" title="Multiple Customers" onClick={() => {
          props.history.push("/dashboard/account/multi");
        }}>
          <PlaylistAddIcon />
        </IconButton>
      </React.Fragment>)
    },
    customToolbarSelect: function(selectedRows) {
      return (
        <IconButton aria-label="expand row" title="Remove Users" onClick={() => {
          let confirm = window.confirm("Are you sure to remove selected users?");

          if (confirm) {
            let formData = new FormData();
            formData.append("type", "remove_selected_users");

            selectedRows.data.map((item, key) => {
              formData.append("id[" + key + "]", updateArr[item.dataIndex].id);
            });

            setLoading(true);
            apiService(config.api_url, "POST", formData, function(response) {
              setUpdateArr(response.data);
              setLoading(false);
            }, function(err) {
              console.log(err);
              setLoading(false);
              alert("Occured error during remove assets, please refresh page.");
            })
          } else {
            return;
          }
        }}>
          <RemoveCircleOutlineOutlinedIcon />
        </IconButton>
      )
    }
  }

  return (
    <div>
      <Helmet>
        <title>Customers | {config.appName}</title>
      </Helmet>
      <GridContainer>
        {loading && <LoadingSpinner />}
        {!loading && (<GridItem xs={12}>
          <Box px={2} py={2}>
            <h3>
              List of Customers
            </h3>
          </Box>
          <MuiThemeProvider theme={getMuiTheme()}>
            <MUIDataTable
              data={updateArr}
              columns={columns}
              options={options} 
              className={classes.dataTable} />
          </MuiThemeProvider>
        </GridItem>)}
      </GridContainer>
    </div>
  );
}
