import React, {useState} from "react";
import {Helmet} from "react-helmet";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import IconButton from '@material-ui/core/IconButton';
import CreateIcon from '@material-ui/icons/Create';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteIcon from '@material-ui/icons/Delete';

import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import Collapse from '@material-ui/core/Collapse';

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';

import { authService } from "services/authService.js";
import apiService from "services/apiService.js";
import config from "constants/config.js";

import {utils} from "utils/index.js";
import LoadingSpinner from "components/Loading/Loading.js";

import styles from "assets/jss/material-dashboard-pro-react/views/viewInvestmentStyle.js";

import MUIDataTable from "mui-datatables";

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

const useStyles = makeStyles(styles);

export default function ViewMusicAssets(props) {
	const classes = useStyles();

  const [loading, setLoading] = useState(true);

  const [editProfile, setEditProfile] = useState(false);
  const [categoryList, setCategoryList] = useState({});
  const [file, setFile] = useState({});
  const [categoryError, setCategoryError] = useState(false);

  const assetId = props.match.params.id;

  const [asset, setAsset] = useState({});

  const [uploading, setUploading] = useState(false);
  
  React.useEffect(() => {
    apiService(config.api_url + "?type=get_music_category", "POST", {}, function(categoryRes) {
      setCategoryList([...categoryRes.data]);

      apiService(config.api_url + "?type=view_musicAsset&id=" + assetId, "POST", {}, function(response) {
        setAsset({...response.data});
        setLoading(false);
      }, function(err) {
        setLoading(false);
        console.log(err);
      })
    }, function(categoryErr) {
      setLoading(false);
      console.log(categoryErr);
    })
  }, []);

  return (
    <div>
      <Helmet>
        <title>View Foreground Asset | {config.appName}</title>
      </Helmet>
      <GridContainer>
        {(loading || uploading) && <LoadingSpinner opacity={uploading ? 1 : 0.2} />}
        {!loading && (<GridItem xs={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={9} lg={6}>
              <Box px={2} py={1} className={classes.titleBox}>
                <h3 className={classes.title}>
                  View Foreground Asset
                </h3>
              </Box>
              <Card>
                <CardBody>
              		{!editProfile && <Box className={classes.rightAlign}>
              			<IconButton onClick={e => {
                      setEditProfile(!editProfile);
                    }} title="Edit Asset">
                      <CreateIcon />
                    </IconButton>
                    <IconButton onClick={e => {
                      let confirm = window.confirm("Are you sure to remove this asset?");
                      if (confirm) {
                        setLoading(true);
                        apiService(config.api_url + "?type=delete_musicAsset&id=" + assetId, "POST", {}, function(response) {
                          setLoading(false);
                          alert("You removed asset successfully!");
                          props.history.push("/dashboard/musicassets");
                        }, function(err) {
                          setLoading(false);
                          alert("Occured error during remove asset.");
                        })
                      }
                    }} title="Remove Asset">
                      <DeleteIcon />
                    </IconButton>
              		</Box>}
              		<ValidatorForm onSubmit={e => {
              			e.preventDefault();

                    if (!asset.category_id || asset.category_id === "0") {
                      setCategoryError(true);
                      return;
                    }

                    let formData = new FormData();
                    formData.append("type", "update_musicasset");
                    formData.append("category_id", asset.category_id);
                    formData.append("id", asset.id);
                    formData.append("title", asset.title);
                    formData.append("tag", asset.tag);
                    if (file && file !== {})
                      formData.append("fileToUpload", file);

                    setUploading(true);
                    apiService(config.api_url, "POST", formData, function(response) {
                      setUploading(false);
                      if (response.data.s3_url) {
                        asset.s3_url = response.data.s3_url;
                      }
                      if (response.data.thumb_url) {
                        asset.thumb_url = response.data.thumb_url;
                      }
                      setAsset({...asset});
                      setFile({});
                      setEditProfile(false);
                    }, function(err) {
                      setUploading(false);
                      console.log(err);
                    })
              		}}>
              			<Box py={1}>
              				<TextValidator 
                        name="title"
                        label="Title"
                        value={asset.title ? asset.title : ""}
                        variant="outlined"
                        fullWidth={true}
                        className={classes.input}
                        validators={['required']}
                        errorMessages={['This field is required']}
                        onChange={e => {
                          asset.title = e.target.value;
                          setAsset({...asset});
                        }}
                        disabled={!editProfile}
                      />
              			</Box>
                    <Box py={1}>
                      <TextField
                        select
                        value={asset.category_id ? parseInt(asset.category_id) : 0}
                        className={classes.selectType}
                        label="Category"
                        variant="outlined"
                        fullWidth={true}
                        onChange={e => {
                          asset.category_id = e.target.value;
                          setAsset({...asset});
                          setCategoryError(false);
                        }}
                        error={categoryError}
                        helperText={categoryError ? "Please select category" : ""}
                        disabled={!editProfile}
                      >
                        <MenuItem aria-label="None" value={0} >Select Type</MenuItem>
                        {categoryList.map((category, key) => {
                          return <MenuItem aria-label="None" value={parseInt(category.id)} key={key} >{category.category}</MenuItem>
                        })}
                      </TextField>
                    </Box>
                    <Box py={1}>
                      File Url: {asset.s3_url ? asset.s3_url : ""}
                    </Box>
                    <Box py={1} className={classes.flexBox}>
                      <Link
                        href="#"
                        component="label"
                        onClick={e => {
                          if (!editProfile) {
                            e.preventDefault();
                            e.stopPropagation();
                            return false;
                          }
                        }}>
                        Change File<input
                          type="file"
                          id="attachFile"
                          hidden
                          onChange={e => {
                            setFile(e.target.files[0]);
                          }}
                          name="attachments"
                        />
                      </Link>
                      {(file !== {}) && <p>
                        {file.name}
                      </p>}
                    </Box>
                    <Box py={1}>
                      <TextValidator 
                        name="tag"
                        label="Tag"
                        value={asset.tag ? asset.tag : ""}
                        variant="outlined"
                        fullWidth={true}
                        className={classes.input}
                        onChange={e => {
                          asset.tag = e.target.value;
                          setAsset({...asset});
                        }}
                        disabled={!editProfile}
                      />
                    </Box>
                    {editProfile && <Box py={1} className={classes.rightButton}>
                    	<Button size="lg" onClick={e => {
                    		setEditProfile(false);
                    	}}>
                    		Cancel
                    	</Button>
                      <Button type="submit" size="lg">
                        Update
                      </Button>
                    </Box>}
              		</ValidatorForm>
              	</CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </GridItem>)}
      </GridContainer>
    </div>
  )
}